import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { IConstellationInfoUpdate, IClusterUnpair, BackupData, PairingCodeConsumed, IClusterDashboard } from "../model/CarbonExternalModels";
import { NOTIFY_CLUSTER_BACKUP_CREATED, NOTIFY_CLUSTER_BACKUP_DELETED, NOTIFY_CLUSTER_CODE_CONSUMED, NOTIFY_CLUSTER_DASHBOARD_UPDATE, NOTIFY_CLUSTER_INFO_UPDATED, NOTIFY_CLUSTER_UNPAIR } from "./messageTypes";
import { removeCluster, updateCluster } from "../store/constellations/constellationsManager";
import carbonStore from "../store/carbonStore";
import { toast } from "react-toastify";
import { BackupsActions } from "../store/backup/backupSlice";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { AppSliceActions } from "../store/app/appSlice";
import { ConstellationsActions } from "../store/constellations/constellationsSlice";

registerMsgHandler(NOTIFY_CLUSTER_INFO_UPDATED, (payload) => {
    const clusterInfos = JSON.parse(payload) as IConstellationInfoUpdate;

    console.debug(clusterInfos);

    updateCluster(clusterInfos);
});
registerMsgHandler(NOTIFY_CLUSTER_UNPAIR, (payload) => {
    const clusterUnpair = JSON.parse(payload) as IClusterUnpair;

    console.debug(clusterUnpair);

    removeCluster(clusterUnpair);
});
registerMsgHandler(NOTIFY_CLUSTER_DASHBOARD_UPDATE, (payload) => {
    const dashboard = JSON.parse(payload) as IClusterDashboard[];

    console.debug(dashboard);

    carbonStore.dispatch(ConstellationsActions.addOrUpdateDashboards(dashboard));
});
registerMsgHandler(NOTIFY_CLUSTER_BACKUP_CREATED, (payload) => {
    const backupData = JSON.parse(payload) as BackupData;

    console.debug(backupData);

    toast('✅ ' + backupData.metadata.name + ' backup request is completed.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",

    });

    carbonStore.dispatch(BackupsActions.addOrUpdateBackup(backupData));
});

registerMsgHandler(NOTIFY_CLUSTER_BACKUP_DELETED, (payload) => {
    const backupData = JSON.parse(payload) as StringID;

    console.debug(backupData);

    carbonStore.dispatch(BackupsActions.removeBackup(backupData));
});

registerMsgHandler(NOTIFY_CLUSTER_CODE_CONSUMED, (payload) => {
    const code = JSON.parse(payload) as PairingCodeConsumed;

    console.debug(code);

    carbonStore.dispatch(AppSliceActions.setPairingCodeConsumed(code));
});