import { KeycloakGroup } from "../../../model/CarbonExternalModels";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    group: KeycloakGroup;
    nameError: string;
    onFieldChange(data): void;
    permissions: UserPermission;
}

export default function UserGroupDetails({ group, onFieldChange, nameError, permissions }: IProps) {
    const canChange = group.editable && permissions.isUserCreator;

    function handleNameChange(event) {
        if (canChange) onFieldChange({ name: event.target.value });
    }
    function handleDescriptionChange(event) {
        if (canChange) onFieldChange({ description: event.target.value });
    }

    return (
        <div className="userfulFormBody">
            <div className="userfulFormHeader">
                <div className="userfulFormRow">
                    <h1 className="userfulFormHeaderTitle">Permission Set Details</h1>
                </div>
                <div className="userfulFormRow">
                    <h3 className="userfulFormHeaderDescription">
                        Permission sets allow you to assign a set of permissions to a group of users.
                    </h3>
                </div>
            </div>
            <div className="userfulFormContents">
                <div className="userfulFormColumn userfulFormSmallGap">
                    <h4 className="userfulFormContentsTitle">Permission Set Details</h4>
                    <h5 className="userfulFormContentsDescription">Details relating to this permission set.</h5>
                </div>
                <div className="userfulFormColumn userfulFormNormalGap">
                    <div className="userfulFormFieldTitle">
                        <h5>Name</h5>
                        <h6>{group.name.length}/60</h6>
                    </div>
                    <input
                        className={`userfulInputField  ${canChange ? "" : "disabled"}`}
                        placeholder="Enter name here..."
                        type="text"
                        maxLength={60}
                        value={group.name}
                        onChange={handleNameChange}
                    />
                    {nameError && <div className="errorText">{nameError}</div>}
                </div>
                <div className="userfulFormColumn userfulFormNormalGap">
                    <div className="userfulFormFieldTitle">
                        <h5>Description</h5>
                        <h6>{group.description.length}/200</h6>
                    </div>
                    <textarea
                        className={`uTextarea  ${canChange ? "" : "disabled"}`}
                        placeholder="Enter description here..."
                        maxLength={200}
                        value={group.description}
                        onChange={handleDescriptionChange}
                    />
                </div>
            </div>
        </div>
    );
}
