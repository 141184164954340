export const path = "/kc";
export const authorize_path = `${path}/auth`;
export const open_path = `${path}/open`;

export const GET_USERMGT_DATA = `${authorize_path}/umd`;
export const GET_LOGIN_EVENTS = `${authorize_path}/event/login`;
export const GET_ADMIN_EVENTS = `${authorize_path}/event/admin`;
export const GET_NODE_STATUS = `${authorize_path}/nodeStatus`;

export const POST_ADD_USER = `${authorize_path}/user/add`;
export const POST_UPDATE_USER = `${authorize_path}/user/update`;
export const POST_DELETE_USER = `${authorize_path}/user/delete`;
export const POST_ENABLE_USER = `${authorize_path}/user/enable`;
export const POST_DISABLE_USER = `${authorize_path}/user/disable`;
export const POST_UPDATE_USER_ATTR = `${authorize_path}/user/updateattr`;
export const POST_UPDATE_PASSWORD = `${authorize_path}/user/resetpassword`;

export const POST_ADD_GROUP = `${authorize_path}/group/add`;
export const POST_COPY_GROUP = `${authorize_path}/group/copy`;
export const POST_DELETE_GROUP = `${authorize_path}/group/delete`;
export const POST_UPDATE_GROUP = `${authorize_path}/group/update`;
export const POST_UPDATE_GROUP_ATTR = `${authorize_path}/group/updateattr`;
export const POST_ASSIGN_USERS_TO_GROUPS = `${authorize_path}/group/assignusers`;
export const POST_ASSIGN_GROUPS_TO_USER = `${authorize_path}/group/assigngroups`;
