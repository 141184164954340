"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMappingGroupName = exports.isMappingGroupMultiWindow = exports.isMappingGroupContainSource = exports.isMapperSourceFromPlaylist = exports.isEdgeMapperCreator = exports.isEdgeMapper = exports.isDestinationInUse = exports.initPassiveMapper = exports.initMappingGroup = exports.initEdgeMappingGroup = exports.getMultiWindowGroupName = exports.getItemIDFromMapperSource = exports.edgePlayerSourcesFilter = exports.edgePlayerDestinationsFilter = exports.createSourceMapperInset = exports.createPlaylistMapperInset = exports.createPassiveMapperOriginData = exports.createEdgeMapperInsetCreator = exports.createDefaultMapperInsetCreatorConfig = exports.createDefaultEdgeName = exports.VIDEO_QUALITY_OPTIONS = exports.VIDEOQUALITY_MEDIUM = exports.VIDEOQUALITY_LOW = exports.VIDEOQUALITY_HIGH = exports.NOT_SET_PASSIVE_MAPPER_ORIGIN = exports.MULTI_WINDOW_GROUP_PREFIX = exports.GPUID_NOT_SET = exports.EMPTY_DESTINATION_IDS = exports.DEFAULT_LOGICAL_GRID_DATA = exports.DEFAULT_LIVE_UPDATE_RESOLUTION = exports.ANY_APPID = void 0;
var _uuid = require("uuid");
var _globalStates = require("../../globalstates/globalStates");
var _CdmAudio = require("../cdm/CdmAudio");
var _common = require("../common");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// export interface TextConfig {
//     colour: string;
//     font: FontName;
//     style: StyleName;
//     textSize: string;
//     shadow: string;
//     outline: string;
//     outlineColour: string;
//     xpos: string;
//     ypos: string;
// }

var NOT_SET_PASSIVE_MAPPER_ORIGIN = exports.NOT_SET_PASSIVE_MAPPER_ORIGIN = {
  id: _common.STRINGID_NOT_SET,
  type: "NONE",
  originApp: {
    value: _common.NOT_SET_VALUE,
    appID: _common.STRINGID_NOT_SET
  }
};
var VIDEOQUALITY_LOW = exports.VIDEOQUALITY_LOW = {
  quality: 50
};
var VIDEOQUALITY_MEDIUM = exports.VIDEOQUALITY_MEDIUM = {
  quality: 80
};
var VIDEOQUALITY_HIGH = exports.VIDEOQUALITY_HIGH = {
  quality: 95
};
// export const VIDEOQUALITY_HIGHEST: VideoQuality = { quality: 100 };

var VIDEO_QUALITY_OPTIONS = exports.VIDEO_QUALITY_OPTIONS = [{
  value: VIDEOQUALITY_LOW.quality,
  label: "CommonUI.MappingGroups.VideoQuality.low"
}, {
  value: VIDEOQUALITY_MEDIUM.quality,
  label: "CommonUI.MappingGroups.VideoQuality.med"
}, {
  value: VIDEOQUALITY_HIGH.quality,
  label: "CommonUI.MappingGroups.VideoQuality.high"
}
// { value: VIDEOQUALITY_HIGHEST.quality, label: 'CommonUI.MappingGroups.VideoQuality.highest' },
];
var DEFAULT_LIVE_UPDATE_RESOLUTION = exports.DEFAULT_LIVE_UPDATE_RESOLUTION = {
  width: 864,
  height: 480
};
var DEFAULT_LOGICAL_GRID_DATA = exports.DEFAULT_LOGICAL_GRID_DATA = {
  columns: 1,
  rows: 1,
  columnsGap: 0,
  rowsGap: 0
};
var isMapperSourceFromPlaylist = exports.isMapperSourceFromPlaylist = function isMapperSourceFromPlaylist(value) {
  return !(0, _common.isIDNotSet)(value.playList.createdFrom);
};
var getItemIDFromMapperSource = exports.getItemIDFromMapperSource = function getItemIDFromMapperSource(value) {
  if (!value) {
    return _objectSpread({}, _common.STRINGID_NOT_SET);
  }
  if (isMapperSourceFromPlaylist(value)) {
    return value.playList.createdFrom;
  }
  return value.playList.items[0].source.createdFrom;
};
var GPUID_NOT_SET = exports.GPUID_NOT_SET = {
  videoEngineID: _common.STRINGID_NOT_SET,
  gpuSlotID: _common.STRINGID_NOT_SET
};
var createDefaultMapperInsetCreatorConfig = exports.createDefaultMapperInsetCreatorConfig = function createDefaultMapperInsetCreatorConfig(name, description, positionInfo, audioSettings, visibility, movable) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: name || "Default",
    description: description || "Default",
    audioData: {
      audioSettings: audioSettings || _CdmAudio.GWTAUDIO_MUTED,
      visibility: "VISIBLE"
    },
    videoData: {
      position: positionInfo || _common.POSITION_FULL,
      visibility: visibility || "VISIBLE",
      movable: movable || "ACTIVE"
    }
  };
};
var createSourceMapperInset = exports.createSourceMapperInset = function createSourceMapperInset(itemID, config) {
  return _objectSpread(_objectSpread({}, config), {}, {
    "@type": "m_source",
    sourceDef: itemID
  });
};
var createPlaylistMapperInset = exports.createPlaylistMapperInset = function createPlaylistMapperInset(itemID, config) {
  return _objectSpread(_objectSpread({}, config), {}, {
    "@type": "m_playlist",
    playListDef: itemID
  });
};
var ANY_APPID = exports.ANY_APPID = {
  value: "*"
};

// quality between 5 ~ 95

// new name

var EMPTY_DESTINATION_IDS = exports.EMPTY_DESTINATION_IDS = {
  groups: [],
  videoWalls: [],
  physicalAudio: [],
  physicalVideo: []
};
var LOW_FPS = 10;
var initMappingGroup = exports.initMappingGroup = function initMappingGroup(isMultiWindow, resolution, appID, waitForDestination) {
  var currentAppID = appID || (0, _globalStates.getGlobalStates)().appID.value;
  var defaultFPS = currentAppID === _common.APP_IDS.Trends ? LOW_FPS : 30.0;
  var newMapper = {
    id: {
      value: (0, _uuid.v4)()
    },
    appInstanceID: {
      value: (0, _uuid.v4)(),
      appID: {
        value: currentAppID
      }
    },
    name: isMultiWindow ? MULTI_WINDOW_GROUP_PREFIX : "",
    description: "",
    canvasSize: {
      width: resolution ? resolution.width : 1920,
      height: resolution ? resolution.height : 1080
    },
    deploymentHints: {
      preferredGPU: _objectSpread({}, GPUID_NOT_SET)
    },
    insets: [],
    destinations: _objectSpread({}, EMPTY_DESTINATION_IDS),
    logicalGridData: {
      columns: 1,
      rows: 1,
      columnsGap: 0,
      rowsGap: 0
    },
    streams: [],
    videoQuality: VIDEOQUALITY_MEDIUM,
    uclientLatency: 0.25,
    frameRate: {
      seconds: 1.0,
      frames: defaultFPS
    },
    provisioningStrategy: waitForDestination ? "WAIT_FOR_DESTINATION" : "KEEP_RUNNING",
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
  return newMapper;
};
var initPassiveMapper = exports.initPassiveMapper = function initPassiveMapper(resolution, appID, edgemapper) {
  return _objectSpread(_objectSpread({}, initMappingGroup(false, resolution, appID)), {}, {
    originType: "SCHEDULED",
    createMapperType: edgemapper ? "EDGE" : "MIXER",
    logicalGridData: DEFAULT_LOGICAL_GRID_DATA
  });
};
var isDestinationInUse = exports.isDestinationInUse = function isDestinationInUse(dest) {
  var _endPoint;
  if (!dest || dest.availability !== "IN_USE") {
    return false;
  }
  if (dest.displays) {
    return dest.availability === "IN_USE";
  }
  return ((_endPoint = dest.endPoint) === null || _endPoint === void 0 || (_endPoint = _endPoint.usage) === null || _endPoint === void 0 || (_endPoint = _endPoint.records) === null || _endPoint === void 0 ? void 0 : _endPoint.length) > 0;
};
var isMappingGroupContainSource = exports.isMappingGroupContainSource = function isMappingGroupContainSource(mappingGroup, sourceID) {
  if (isEdgeMapper(mappingGroup)) {
    return mappingGroup.inset.playList.items[0] && mappingGroup.inset.playList.items[0].source.createdFrom.value === sourceID.value;
  }
  var _iterator = _createForOfIteratorHelper(mappingGroup.insets),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var inset = _step.value;
      var _iterator2 = _createForOfIteratorHelper(inset.playList.items),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var playListItem = _step2.value;
          if (Array.isArray(sourceID)) {
            var _iterator3 = _createForOfIteratorHelper(sourceID),
              _step3;
            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var s = _step3.value;
                if (playListItem.source.createdFrom.value === s.value) {
                  return true;
                }
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
          } else {
            if (playListItem.source.createdFrom.value === sourceID.value) {
              return true;
            }
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return false;
};
var MULTI_WINDOW_GROUP_PREFIX = exports.MULTI_WINDOW_GROUP_PREFIX = "Auto_Created_Multi_Window_";
var isMappingGroupMultiWindow = exports.isMappingGroupMultiWindow = function isMappingGroupMultiWindow(mappingGroup) {
  return mappingGroup.name.startsWith(MULTI_WINDOW_GROUP_PREFIX);
};
var getMultiWindowGroupName = exports.getMultiWindowGroupName = function getMultiWindowGroupName(data) {
  if (!data) {
    return "Unknown";
  }
  if (typeof data === "string") {
    return data.replace(MULTI_WINDOW_GROUP_PREFIX, "");
  }
  return data.name.replace(MULTI_WINDOW_GROUP_PREFIX, "");
};
var updateMappingGroupName = exports.updateMappingGroupName = function updateMappingGroupName(mappingGroup, nameUpdate) {
  return _objectSpread(_objectSpread({}, mappingGroup), {}, {
    name: isMappingGroupMultiWindow(mappingGroup) ? MULTI_WINDOW_GROUP_PREFIX + nameUpdate : nameUpdate
  });
};
var isEdgeMapper = exports.isEdgeMapper = function isEdgeMapper(comp) {
  return comp && !comp.insets;
};
var isEdgeMapperCreator = exports.isEdgeMapperCreator = function isEdgeMapperCreator(comp) {
  return comp && !comp.insets;
};
var createEdgeMapperInsetCreator = exports.createEdgeMapperInsetCreator = function createEdgeMapperInsetCreator(sourceID, name, description) {
  return createSourceMapperInset(sourceID || _common.STRINGID_NOT_SET, createDefaultMapperInsetCreatorConfig(name || "", description || ""));
};
var createDefaultEdgeName = exports.createDefaultEdgeName = function createDefaultEdgeName(mappingGroups) {
  var name = "uClient Player";
  if (mappingGroups.findIndex(function (item) {
    return name === item.name;
  }) < 0) {
    return name;
  }
  var duplicateIndex = 1;
  var purposedName = "".concat(name, " (").concat(duplicateIndex, ")");
  while (mappingGroups.findIndex(function (item) {
    return purposedName === item.name;
  }) >= 0) {
    duplicateIndex++;
    purposedName = "".concat(name, " (").concat(duplicateIndex, ")");
  }
  return purposedName;
};
var initEdgeMappingGroup = exports.initEdgeMappingGroup = function initEdgeMappingGroup(name, appID) {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    appInstanceID: {
      value: (0, _uuid.v4)(),
      appID: {
        value: appID || (0, _globalStates.getGlobalStates)().appID.value
      }
    },
    name: name || "uClient Player",
    description: "",
    destinations: _objectSpread({}, EMPTY_DESTINATION_IDS),
    videoQuality: VIDEOQUALITY_MEDIUM,
    userfulSecurityData: (0, _common.EMPTY_USERFUL_SECURITY_DATA)()
  };
};
var edgePlayerSourcesFilter = exports.edgePlayerSourcesFilter = function edgePlayerSourcesFilter(data) {
  if (data.type === "playlist") {
    return false;
  }
  var item = data.item;
  return item.type.value === "Signage_Player" || item.type.value === "Web_Browser";
};
var edgePlayerDestinationsFilter = exports.edgePlayerDestinationsFilter = function edgePlayerDestinationsFilter(data) {
  if (data.type === "physicalVideo") {
    var item = data.item;
    return item.endPoint.id.seatID.value.includes("uclient");
  }
  if (data.type === "videoWalls") {
    var _item = data.item;
    return _item.displays.findIndex(function (i) {
      return i.endPoint.id.seatID.value !== _common.NOT_SET_VALUE && !i.endPoint.id.seatID.value.includes("uclient");
    }) < 0;
  }
  return false;
};
var createPassiveMapperOriginData = exports.createPassiveMapperOriginData = function createPassiveMapperOriginData(passiveMapper) {
  if (!passiveMapper) {
    return _objectSpread({}, NOT_SET_PASSIVE_MAPPER_ORIGIN);
  }
  return {
    id: passiveMapper.id,
    type: passiveMapper.originType,
    originApp: passiveMapper.appInstanceID
  };
};