import React, { useEffect, useState } from "react";
import { faCircleExclamation, faMultiply, faPenToSquare, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal, Offcanvas } from "react-bootstrap";
import { IConstellationDefinition, IConstellationInfo } from "../../../model/CarbonExternalModels";
import { objectEqual } from "../../../common/functions/objectComparison";
import { initIConstellationDefinition } from "../../../store/constellations/constellationsUtils";
import { apiCreateConstellation, apiUpdateConstellation } from "../../../messages/api/gcmAPICallers";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { selectConstellations, useCarbonSelector } from "../../../store/carbonStore";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    type: "PRIMARY" | "SECONDARY" | "DROPDOWN";
    constellation?: IConstellationDefinition;
    permissions: UserPermission;
}

export default function CreateConstellation(props: IProps) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [registerChange, setRegisterChange] = useState<boolean>(false);

    const [originalConstellation, setOriginalConstellation] = useState<IConstellationDefinition>(
        props.constellation ? props.constellation : initIConstellationDefinition()
    );
    const [currentConstellation, setCurrentConstellation] = useState<IConstellationDefinition>(originalConstellation);

    // Data
    const constellations: { [id: string]: IConstellationInfo } = useCarbonSelector(selectConstellations);
    const allConstellations: IConstellationInfo[] = [];
    for (const property in constellations) {
        allConstellations.push(constellations[property]);
    }

    const foundChanges = !objectEqual(originalConstellation, currentConstellation);

    const nameError =
        (allConstellations.find((c) => c.definition.name === currentConstellation.name) &&
            currentConstellation.name !== originalConstellation.name) || currentConstellation.name.length === 0
            ? "Please make sure the name is unique and is not empty."
            : "";

    useEffect(() => {
        if (props.constellation) setOriginalConstellation(props.constellation);
    }, [props.constellation]);

    useEffect(() => {
        setCurrentConstellation(originalConstellation);
    }, [originalConstellation]);

    function handleNameChange(event) {
        setRegisterChange(true);
        setCurrentConstellation({ ...currentConstellation, name: event.target.value });
    }
    function handleDescriptionChange(event) {
        setRegisterChange(true);
        setCurrentConstellation({ ...currentConstellation, description: event.target.value });
    }
    const onFormShow = () => {
        setShowForm(true);
    };
    const onFormHide = () => {
        if (foundChanges) {
            setShowConfirmation(true);
        } else {
            onRestore();
            setShowForm(false);
        }
    };
    const onRestore = () => {
        setRegisterChange(false);
        if (props.constellation) {
            setOriginalConstellation(props.constellation);
            setCurrentConstellation(props.constellation);
        } else {
            setOriginalConstellation(initIConstellationDefinition());
        }
    };

    const onCreate = () => {
        if (props.constellation) {
            apiUpdateConstellation(getGlobalStates().keycloak.token, currentConstellation);
        } else {
            apiCreateConstellation(getGlobalStates().keycloak.token, currentConstellation);
        }
        onRestore();
        setShowForm(false);
    };

    // console.warn(props.permissions, props.type);
    if (!props.permissions.isConstellationCreator) return null;

    return (
        <>
            {props.type === "PRIMARY" && (
                <button className="createButton" onClick={(e) => onFormShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                    Create Constellation
                </button>
            )}
            {props.type === "SECONDARY" && (
                <button className="secondaryButton" onClick={(e) => onFormShow()}>
                    Create Constellation
                </button>
            )}
            {props.type === "DROPDOWN" && (
                <Dropdown.Item className="dropdownItem" onClick={(e) => onFormShow()}>
                    <div className="iconInside">
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </div>
                    <div className="textInside">Edit</div>
                </Dropdown.Item>
            )}

            <Offcanvas show={showForm} onHide={onFormHide} placement="end">
                <div className="userfulFormBody">
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">
                                {props.constellation ? "Edit Constellation" : "Create Constellation"}
                            </h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">
                                Constellations are used to group together related clusters.
                            </h3>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">Constellation Details</h4>
                            <h5 className="userfulFormContentsDescription">
                                Details relating to this Constellation group.
                            </h5>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Name</h5>
                                <h6>{currentConstellation.name.length}/60</h6>
                            </div>
                            <input
                                className="userfulInputField"
                                placeholder="Enter name here..."
                                type="text"
                                maxLength={60}
                                value={currentConstellation.name}
                                onChange={handleNameChange}
                            />
                            {nameError && registerChange && <div className="errorText">{nameError}</div>}
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Description</h5>
                                <h6>{currentConstellation.description.length}/200</h6>
                            </div>
                            <textarea
                                className="uTextarea"
                                placeholder="Enter description here..."
                                maxLength={200}
                                value={currentConstellation.description}
                                onChange={handleDescriptionChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="userfulFormFooter">
                    <button className="secondaryUserfulButton" onClick={onFormHide}>
                        Cancel
                    </button>
                    <button
                        className="primaryUserfulButton"
                        onClick={onCreate}
                        disabled={nameError.length > 0 || !foundChanges}
                    >
                        {props.constellation ? "Save Changes" : "Create"}
                    </button>
                </div>
            </Offcanvas>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Are you sure you want to leave?</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            Are you sure you want to leave without saving your changes? Any unsaved modifications will
                            be lost.
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowForm(false);
                                setShowConfirmation(false);
                                onRestore();
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
