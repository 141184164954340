import { faPlus, faPenToSquare, faMultiply, faCircleExclamation, faEye, faEyeSlash, faCheck, faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Dropdown, Offcanvas, Modal } from "react-bootstrap";
import { objectEqual } from "../../common/functions/objectComparison";
import { useCarbonSelector, selectUsers, selectUserGroups, useCarbonDispatch } from "../../store/carbonStore";
import { initKeycloakUser } from "../../store/userManagement/userManagementUtils";
import { CreateKeycloakUser, KeycloakGroup, KeycloakUser, UpdateKeycloakUser, UserfulGroup, UserfulUser } from "../../model/CarbonExternalModels";
import { isEmailValid, isPasswordValid } from "../../common/functions/password";
import UserGroupMultiselect from "../userGroups/userGroupMultiselect/UserGroupMultiselect";
import { apiCreateUser, apiUpdateUser } from "../../messages/api/gcmUserManagement";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { UserPermission } from "../../model/CarbonPermissionModels";
import { AppSliceActions } from "../../store/app/appSlice";

interface IProps {
    type: "PRIMARY" | "SECONDARY" | "DROPDOWN";
    user?: KeycloakUser;
    permissions: UserPermission;
}

export default function UserForm(props: IProps) {
    const dispatch = useCarbonDispatch();

    const [showForm, setShowForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [registerChange, setRegisterChange] = useState<boolean>(false);

    const [originalUser, setOriginalUser] = useState<KeycloakUser>(props.user ? props.user : initKeycloakUser());
    const [currentUser, setCurrentUser] = useState<KeycloakUser>(originalUser);

    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [temporary, setTemporary] = useState<boolean>(true);

    // Data
    const users: { [id: string]: UserfulUser } = useCarbonSelector(selectUsers);
    const userGroups: { [id: string]: UserfulGroup } = useCarbonSelector(selectUserGroups);

    const allUsers: KeycloakUser[] = [];
    const allUserGroups: KeycloakGroup[] = [];

    for (const property in users) {
        allUsers.push(users[property].user);
    }
    for (const property in userGroups) {
        allUserGroups.push(userGroups[property].group);
    }

    const hasPermissionsSet = allUserGroups.length > 1;

    const nameError =
        allUsers.find((c) => c.userProfile.userName === currentUser.userProfile.userName) && currentUser.userProfile.userName !== originalUser.userProfile.userName
            && currentUser.userProfile.userName.length > 0
            ? "Please make sure the name is unique and is not empty."
            : "";

    const passwordError = isPasswordValid(password) ? "" : "Please make sure that password satisfies requirements.";
    const confirmPasswordError = password === confirmPassword ? "" : "Please make sure that passwords match.";
    const userGroupError = currentUser.groups.length >= 1 ? "" : "Please make sure user belongs to at least one group.";
    const emailError =
        !(allUsers.find((c) => c.userProfile.email === currentUser.userProfile.email) && currentUser.userProfile.email !== originalUser.userProfile.email) &&
            isEmailValid(currentUser.userProfile.email)
            ? ""
            : "Please make sure to enter a valid and unique email address.";
    const foundChanges = !objectEqual(originalUser, currentUser);

    useEffect(() => {
        if (props.user) setOriginalUser(props.user);
    }, [props.user]);

    useEffect(() => {
        setCurrentUser(originalUser);
    }, [originalUser]);

    function handleNameChange(event) {
        setRegisterChange(true)
        setCurrentUser({ ...currentUser, userProfile: { ...currentUser.userProfile, userName: event.target.value } });
    }
    function handleFirstNameChange(event) {
        setRegisterChange(true)
        setCurrentUser({ ...currentUser, userProfile: { ...currentUser.userProfile, firstName: event.target.value } });
    }
    function handleLastNameChange(event) {
        setRegisterChange(true)
        setCurrentUser({ ...currentUser, userProfile: { ...currentUser.userProfile, lastName: event.target.value } });
    }
    function handleEmailChange(event) {
        setRegisterChange(true)
        setCurrentUser({ ...currentUser, userProfile: { ...currentUser.userProfile, email: event.target.value } });
    }
    function handlePasswordChange(event) {
        setRegisterChange(true)
        setPassword(event.target.value);
    }
    function toggleShowPassword(event) {
        setRegisterChange(true)
        setShowPassword(!showPassword);
    }
    function handleConfirmPasswordChange(event) {
        setRegisterChange(true)
        setConfirmPassword(event.target.value);
    }
    function toggleShowConfirmPassword(event) {
        setRegisterChange(true)
        setShowConfirmPassword(!showConfirmPassword);
    }
    function toggleTemporary(event) {
        setRegisterChange(true)
        setTemporary(!temporary);
    }
    function onUserGroupsChange(data) {
        setRegisterChange(true)
        setCurrentUser({ ...currentUser, ...data });
    }

    const onFormShow = () => {
        if (hasPermissionsSet) {
            setShowForm(true);
        } else {
            setShowNotification(true);
        }
    };
    const onFormHide = () => {
        if (foundChanges) {
            setShowConfirmation(true);
        } else {
            onRestore();
            setShowForm(false);
        }
    };
    const onRestore = () => {
        setPassword("");
        setConfirmPassword("");
        setShowPassword(false);
        setShowConfirmPassword(false);

        if (props.user) {
            setOriginalUser(props.user);
            setCurrentUser(props.user);
        } else {
            setOriginalUser(initKeycloakUser());
        }
    };

    const onCreate = () => {
        if (props.user) {
            const updateKeycloakUser: UpdateKeycloakUser = {
                id: currentUser.id,
                enableMfa: currentUser.mfaEnable,
                groups: currentUser.groups,
                userProfile: currentUser.userProfile,
            };

            apiUpdateUser(getGlobalStates().keycloak.token, updateKeycloakUser);
        } else {
            const createKeycloakUser: CreateKeycloakUser = {
                enableMfa: currentUser.mfaEnable,
                groups: currentUser.groups,
                userProfile: currentUser.userProfile,
                password: {
                    password: password,
                    temporary: temporary,
                },
            };

            // console.warn(createKeycloakUser);

            apiCreateUser(getGlobalStates().keycloak.token, createKeycloakUser);
        }
        onRestore();
        setShowForm(false);
    };

    const canCreate = nameError.length === 0 && passwordError.length === 0 && confirmPasswordError.length === 0 && emailError.length === 0 && userGroupError.length === 0 && foundChanges;

    if (!props.permissions.isUserCreator) return null;

    return (
        <>
            {props.type === "PRIMARY" && (
                <button className="createButton" onClick={(e) => onFormShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                    Create User
                </button>
            )}
            {props.type === "SECONDARY" && (
                <button className="secondaryButton" onClick={(e) => onFormShow()}>
                    Create User
                </button>
            )}
            {props.type === "DROPDOWN" && !props.user && (
                <Dropdown.Item className="dropdownItem" onClick={(e) => onFormShow()}>
                    <div className="iconInside">
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <div className="textInside">Create User</div>
                </Dropdown.Item>
            )}

            {props.type === "DROPDOWN" && props.user && (
                <Dropdown.Item className="dropdownItem" onClick={(e) => onFormShow()}>
                    <div className="iconInside">
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </div>
                    <div className="textInside">Edit</div>
                </Dropdown.Item>
            )}

            <Offcanvas show={showForm} onHide={onFormHide} placement="end">
                <div className="userfulFormBody noselect">
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">{props.user ? "Edit User" : "Create User"}</h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">Make sure that user has a unique username and a safe password.</h3>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">User Details</h4>
                            <h5 className="userfulFormContentsDescription">Details relating to this user.</h5>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Username</h5>
                                <h6>{currentUser.userProfile.userName.length}/60</h6>
                            </div>
                            <input
                                disabled={props.user ? true : false}
                                className="userfulInputField"
                                placeholder="Enter name here..."
                                type="text"
                                maxLength={60}
                                value={currentUser.userProfile.userName}
                                onChange={handleNameChange}
                            />
                            {nameError && registerChange && <div className="errorText">{nameError}</div>}
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="fieldRowWrapper">
                                <div className="userfulFormColumn userfulFormNormalGap">
                                    <div className="userfulFormFieldTitle">
                                        <h5>First Name</h5>
                                        {/* <h6>{currentUser.userProfile.firstName.length}/60</h6> */}
                                    </div>
                                    <input
                                        className="userfulInputField"
                                        placeholder="Enter first name here..."
                                        type="text"
                                        maxLength={60}
                                        value={currentUser.userProfile.firstName ? currentUser.userProfile.firstName : ""}
                                        onChange={handleFirstNameChange}
                                    />
                                </div>
                                <div className="userfulFormColumn userfulFormNormalGap">
                                    <div className="userfulFormFieldTitle">
                                        <h5>Last Name</h5>
                                        {/* <h6>{currentUser.userProfile.firstName.length}/60</h6> */}
                                    </div>
                                    <input
                                        className="userfulInputField"
                                        placeholder="Enter last name here..."
                                        type="text"
                                        maxLength={60}
                                        value={currentUser.userProfile.lastName ? currentUser.userProfile.lastName : ""}
                                        onChange={handleLastNameChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Email Address</h5>
                            </div>
                            <input
                                className="userfulInputField"
                                placeholder="Enter email address here..."
                                type="email"
                                maxLength={200}
                                value={currentUser.userProfile.email ? currentUser.userProfile.email : ""}
                                onChange={handleEmailChange}
                            />
                            {emailError && registerChange && <div className="errorText">{emailError}</div>}
                        </div>
                        {!props.user && (
                            <>
                                <div className="userfulFormColumn userfulFormNormalGap">
                                    <div className="userfulFormFieldTitle">
                                        <div className="cluster shortGap">
                                            <h5>Password</h5>
                                            <UserfulTooltip title="Password must be at least 8 characters long, must contain at least one uppercase letter, must contain at least one lowercase letter, at least one number, and at least one special symbol.">
                                                <div>
                                                    <FontAwesomeIcon icon={faCircleQuestion} />
                                                </div>
                                            </UserfulTooltip>
                                        </div>
                                        {/* <div
                                            className="fieldRowWrapper"
                                            onClick={toggleTemporary}
                                            style={{ cursor: "pointer", width: 170 }}
                                        >
                                            <div className={`checkbox ${temporary ? "checked" : ""} `}>
                                                {temporary ? <FontAwesomeIcon icon={faCheck} /> : null}
                                            </div>
                                            <div className="fieldColumnWrapper shortGap">
                                                <div className="mainText">Temporary Password</div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="passwordContainer">
                                        {showPassword ? (
                                            <input className="userfulInputField" placeholder="Enter password here..." type="text" maxLength={200} value={password} onChange={handlePasswordChange} />
                                        ) : (
                                            <input className="userfulInputField" placeholder="Enter password here..." type="password" maxLength={200} value={password} onChange={handlePasswordChange} />
                                        )}
                                        <button className="userfulInputFieldButton" onClick={toggleShowPassword}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    </div>
                                    {passwordError && registerChange && <div className="errorText">{passwordError}</div>}
                                </div>
                                <div className="userfulFormColumn userfulFormNormalGap">
                                    <div className="userfulFormFieldTitle">
                                        <h5>Confirm Password</h5>
                                    </div>
                                    <div className="passwordContainer">
                                        {showConfirmPassword ? (
                                            <input
                                                className="userfulInputField"
                                                placeholder="Confirm password here..."
                                                type="text"
                                                maxLength={200}
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                            />
                                        ) : (
                                            <input
                                                className="userfulInputField"
                                                placeholder="Confirm password here..."
                                                type="password"
                                                maxLength={200}
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                            />
                                        )}
                                        <button className="userfulInputFieldButton" onClick={toggleShowConfirmPassword}>
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                        </button>
                                    </div>
                                    {confirmPasswordError && registerChange && <div className="errorText">{confirmPasswordError}</div>}
                                </div>
                            </>
                        )}
                        <div className="userfulFormColumn userfulFormNormalGap">
                            {/* <div className="userfulFormColumn userfulFormSmallGap">
                                        <h4 className="userfulFormContentsTitle">Include Media</h4>
                                        <h5 className="userfulFormContentsDescription">
                                            Should this backup include media files, this will make the size of the backup file substantially larger.
                                        </h5>
                                    </div> */}
                            <div className="compositeContainer">
                                <div
                                    className={`fieldRowWrapper `}
                                    onClick={(e) => {
                                        setCurrentUser({ ...currentUser, mfaEnable: !currentUser.mfaEnable })
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className={`checkbox ${currentUser.mfaEnable ? "checked" : null} `}>
                                        {currentUser.mfaEnable ? <FontAwesomeIcon icon={faCheck} /> : null}
                                    </div>
                                    <div className="fieldColumnWrapper shortGap">
                                        <div className="mainText">Multifactor Authentication</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">Permission sets</h4>
                            <h5 className="userfulFormContentsDescription">Assign this user to one or multiple permission sets.</h5>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Permission sets</h5>
                            </div>
                            <UserGroupMultiselect user={currentUser} onChange={onUserGroupsChange} />
                            {userGroupError && registerChange && <div className="errorText">{userGroupError}</div>}
                        </div>
                    </div>
                </div>
                <div className="userfulFormFooter">
                    <button className="secondaryUserfulButton" onClick={onFormHide}>
                        Cancel
                    </button>
                    <button className="primaryUserfulButton" onClick={onCreate} disabled={!canCreate}>
                        {props.user ? "Save Changes" : "Create"}
                    </button>
                </div>
            </Offcanvas>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Are you sure you want to leave?</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">Are you sure you want to leave without saving your changes? Any unsaved modifications will be lost.</div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowForm(false);
                                setShowConfirmation(false);
                                onRestore();
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                show={showNotification}
                onHide={() => {
                    setShowNotification(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Missing Permission Set</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowNotification(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            Please create at least one permission set before creating a new user. <br />
                            <div className="description-container">Note: The default "Administrators" permission set cannot be assigned to any user except "admin".</div>
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="primaryUserfulButton"
                            onClick={(e) => {
                                setShowNotification(false);
                                dispatch(AppSliceActions.setAppView({ id: { value: "NOT_SET" }, type: "USER_GROUPS" }));
                            }}
                        >
                            Go to Permission Set
                        </button>

                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowNotification(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
