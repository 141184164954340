import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { KeycloakUser } from "../../model/CarbonExternalModels";
import { AppSliceActions } from "../../store/app/appSlice";
import { selectUsers, useCarbonDispatch, useCarbonSelector } from "../../store/carbonStore";
import UserForm from "./UserForm";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { Button } from "userful-chronos-common-ui/dist";

interface IProps {
    user: KeycloakUser;
}

export default function UserDropdown(props: IProps) {
    const dispatch = useCarbonDispatch();

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    const users = useCarbonSelector(selectUsers);
    const allUsers: KeycloakUser[] = [];
    for (const property in users) {
        allUsers.push(users[property].user);
    }

    return (
        <Dropdown className="operationCenterDropdown">
            <Dropdown.Toggle bsPrefix="dropdownButtonSwitch" id="dropdownToggle" as={Button}>
                <div className="textInside">{props.user.userProfile.userName}</div>
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdownMenuSwitch"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                {allUsers.length > 1 && (
                    <>
                        {allUsers.map((c) => {
                            if (c.id.value !== props.user.id.value) {
                                return (
                                    <Dropdown.Item
                                        key={c.id.value}
                                        className="dropdownItem"
                                        onClick={() => {
                                            dispatch(
                                                AppSliceActions.setAppView({
                                                    type: "USER",
                                                    id: c.id,
                                                })
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faUser} />
                                        <div className="textInside">{c.userProfile.userName}</div>
                                    </Dropdown.Item>
                                );
                            }
                            return null;
                        })}
                        <hr />
                    </>
                )}
                <UserForm type={"DROPDOWN"} permissions={permissions} />
            </Dropdown.Menu>
        </Dropdown>
    );
}
