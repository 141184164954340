import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from "react-redux";
import novastarSlice from "./novastarSlice";
import megapixelSlice from "./megapixelSlice";
import epicSlice from "./epicSlice";
import ledActionsSlice from "./ledActionsSlice";
import powerbiSlice from './powerbiSlice';
import tableauSlice from "./tableauSlice";

export const IntegrationContext = React.createContext(null);

const integrationStore = configureStore({
    reducer: {
        novastarStore: novastarSlice,
        megapixelStore: megapixelSlice,
        epicStore: epicSlice,
        ledActionsStore: ledActionsSlice,
        powerbiStore: powerbiSlice,
        tableauStore: tableauSlice
    },
});
export default integrationStore;

export type State = ReturnType<typeof integrationStore.getState>;
export type Dispatch = typeof integrationStore.dispatch;
export const useIntegrationSelector: TypedUseSelectorHook<State> = createSelectorHook(IntegrationContext);
export const useIntegrationDispatch = createDispatchHook<Dispatch>(IntegrationContext);
