import React, { useEffect } from "react";
import { IAppView, TNavigationStatus } from "../store/app/appUtils";
import {
    useCarbonSelector,
    selectAppView,
    selectConstellations,
    selectUserGroups,
    selectAppNavigationStatus,
    useCarbonDispatch,
} from "../store/carbonStore";
import { useMount } from "react-use";

import ConstellationsTable from "./constellations/ConstellationsTable";

import "./Main.scss";
import "./../styles/UFormFields.scss";
import "./../styles/ActiveSourceDropdown.scss";
import "./../styles/clusters.scss";
import "./../styles/buttons.scss";
import "./../styles/forms.scss";
import "./../styles/multiStateToggle.scss";
import "./../styles/table.scss";
import "./../styles/badges.scss";
import "./../styles/badges.scss";
import "./../styles/dropdown.scss";
import "./../styles/confirmationDialog.scss";

import ClustersTable from "./clusters/table/ClustersTable";
import { apiGetBackups, apiGetConstellations, apiGetDashboards, apiGetHealth } from "../messages/api/gcmAPICallers";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { IConstellationInfo, UserfulUser, UserfulGroup } from "../model/CarbonExternalModels";
import { selectUsers } from "../store/carbonStore";
import Navigation from "./navigation/Navigation";
import UsersTable from "./users/UsersTable";
import UserGroupsTable from "./userGroups/UserGroupsTable";
import UserGroupPage from "./userGroups/UserGroupPage";
import UserPage from "./users/UserPage";

import { apiGetUser, apiGetUserGroup, apiGetUserGroups, apiGetUserManagement, apiGetUsers } from "../messages/api/gcmUserManagement";
import AppStatus from "./app/AppStatus";
import { hasAtLeastOnePermission } from "../model/CarbonPermissionModels";
import EmptyPlaceholder from "../common/components/placeholders/EmptyPlaceholder";
import { AppSliceActions } from "../store/app/appSlice";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import "./../messages/messageReceiver"
import BackupAndRestoreTable from "./backupAndRestore/BackupAndRestoreTable";

import ClusterPage from "./clusters/cluster/ClusterPage";
import DevicePage from "./devices/device/DevicePage";

export default function Main() {
    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;
    const appView: IAppView = useCarbonSelector(selectAppView);
    const constellations = useCarbonSelector(selectConstellations);
    const users = useCarbonSelector(selectUsers);
    const userGroups = useCarbonSelector(selectUserGroups);

    const dispatch = useCarbonDispatch();

    const navigationStatus: TNavigationStatus = useCarbonSelector(selectAppNavigationStatus);

    // Found objects:
    let foundObject;

    if (appView.type === "CONSTELLATION") foundObject = constellations[appView.id.value];
    else if (appView.type === "CLUSTER") {
        let foundConstellation;
        let foundCluster;

        for (const property in constellations) {
            const constellation = constellations[property];

            const cluster = constellation.cluster.find((c) => c.id.value === appView.id.value);

            if (cluster) {
                foundCluster = cluster;
                foundConstellation = constellation;
                break;
            }
        }

        if (foundCluster && foundConstellation) {
            foundObject = { cluster: foundCluster, constellation: foundConstellation };
        }

        else foundObject = null;
    } else if (appView.type === "DEVICE") {
        let foundConstellation;
        let foundCluster;
        let foundDevice;

        for (const property in constellations) {
            const constellation = constellations[property];

            for (let i = 0; i < constellation.cluster.length; i++) {
                const cluster = constellation.cluster[i];

                if (cluster) {
                    const device = cluster.clusterDeviceInfo.find((c) => c.id.value === appView.id.value);

                    if (device) {
                        foundCluster = cluster;
                        foundConstellation = constellation;
                        foundDevice = device;
                        break;
                    }
                }
            }
        }

        if (foundCluster && foundConstellation && foundDevice)
            foundObject = { cluster: foundCluster, constellation: foundConstellation, device: foundDevice };
        else foundObject = null;
    } else if (appView.type === "USER") foundObject = users[appView.id.value];
    else if (appView.type === "USER_GROUP") foundObject = userGroups[appView.id.value];

    useMount(() => {
        apiGetConstellations(getGlobalStates().keycloak.token);
        apiGetUserManagement(getGlobalStates().keycloak.token);
        apiGetBackups(getGlobalStates().keycloak.token);
        apiGetHealth(getGlobalStates().keycloak.token);
        apiGetDashboards(getGlobalStates().keycloak.token);
    });

    useEffect(() => {
        if (appView.type === "CONSTELLATIONS") apiGetConstellations(getGlobalStates().keycloak.token);
        else if (appView.type === "BACKUP_AND_RESTORE") apiGetBackups(getGlobalStates().keycloak.token);
        else if (appView.type === "USER_GROUPS") apiGetUserGroups(getGlobalStates().keycloak.token);
        else if (appView.type === "USER_GROUP") apiGetUserGroup(getGlobalStates().keycloak.token, appView.id);
        else if (appView.type === "USERS") apiGetUsers(getGlobalStates().keycloak.token);
        else if (appView.type === "USER") apiGetUser(getGlobalStates().keycloak.token, appView.id);
    }, [appView]);

    useEffect(() => {
        if (appView.type === "CONSTELLATIONS" && !permissions.isConstellationViewer && permissions.isUserViewer) {
            dispatch(AppSliceActions.setAppView({ type: "USER_GROUPS", id: { value: "NOT_SET" } }));
        }
    }, [permissions]);

    // console.warn(userGroups);

    return (
        <div className="appWrapper noselect">
            {hasAtLeastOnePermission(permissions) ? (
                <>
                    <Navigation />

                    <div
                        className="contentWrapper"
                        style={{ width: navigationStatus === "SHOWN" ? "calc(100vw - 320px)" : "100%" }}
                    >
                        {appView.type === "CONSTELLATIONS" && <ConstellationsTable constellations={constellations} />}
                        {appView.type === "CONSTELLATION" && foundObject && (
                            <ClustersTable constellation={foundObject as IConstellationInfo} permissions={permissions} />
                        )}
                        {appView.type === "BACKUP_AND_RESTORE" && <BackupAndRestoreTable constellations={constellations} />}
                        {appView.type === "DEVICE" && foundObject && <DevicePage foundObject={foundObject} />}
                        {appView.type === "CLUSTER" && foundObject && <ClusterPage foundObject={foundObject} />}
                        {appView.type === "USERS" && <UsersTable users={users} groups={userGroups} />}
                        {appView.type === "USER" && foundObject && <UserPage user={foundObject as UserfulUser} />}
                        {appView.type === "USER_GROUPS" && <UserGroupsTable groups={userGroups} users={users} />}
                        {appView.type === "USER_GROUP" && <UserGroupPage group={foundObject as UserfulGroup} />}
                        <AppStatus />
                    </div>
                </>
            ) : (
                <EmptyPlaceholder
                    img="./images/noRoomImage.png"
                    title={"User Permissions"}
                    description={
                        "Unfortunately, it appears that you do not currently possess any permissions. To access the desired resources or functionalities, kindly reach out to your system administrator and request the necessary permissions. Once granted, you'll be able to navigate through the system with ease. Thank you for your understanding and cooperation."
                    }
                />
            )}
        </div>
    );
}
