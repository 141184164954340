import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    UserfulGroup,
    KeycloakRole,
    UserfulUser,
} from "../../model/CarbonExternalModels";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { TUserGroupTab } from "./userManagementUtils";

interface IState {
    roles: { [id: string]: KeycloakRole };
    users: { [id: string]: UserfulUser };
    userGroups: { [id: string]: UserfulGroup };
    userGroupTab: TUserGroupTab;
}

const initialState: IState = {
    roles: {},
    users: {},
    userGroups: {},
    userGroupTab: "Roles",
};

export const userManagementSlice = createSlice({
    name: "userManagementSlice",
    initialState,
    reducers: {
        // Roles Management:
        setRoles: (state, action: PayloadAction<{ [id: string]: KeycloakRole }>) => {
            state.roles = action.payload;
        },
        addORUpdateRole: (state, action: PayloadAction<KeycloakRole>) => {
            const role: KeycloakRole = action.payload;
            state.roles[role.id.value] = role;
        },
        removeRole: (state, action: PayloadAction<StringID>) => {
            const id: StringID = action.payload;
            const role: KeycloakRole = state.roles[id.value];

            if (role) {
                delete state.roles[id.value];
            }
        },
        // Users Management:
        setUsers: (state, action: PayloadAction<{ [id: string]: UserfulUser }>) => {
            state.users = action.payload;
        },
        addORUpdateUser: (state, action: PayloadAction<UserfulUser>) => {
            const user: UserfulUser = action.payload;
            state.users[user.id.value] = user;
        },
        removeUser: (state, action: PayloadAction<StringID>) => {
            const id: StringID = action.payload;
            const user: UserfulUser = state.users[id.value];

            if (user) {
                delete state.users[id.value];
            }
        },
        // User Groups Management:
        setUserGroups: (state, action: PayloadAction<{ [id: string]: UserfulGroup }>) => {
            state.userGroups = action.payload;
        },
        addORUpdateUserGroup: (state, action: PayloadAction<UserfulGroup>) => {
            const userGroup: UserfulGroup = action.payload;
            state.userGroups[userGroup.id.value] = userGroup;
        },
        removeUserGroup: (state, action: PayloadAction<StringID>) => {
            const id: StringID = action.payload;
            const userGroups: UserfulGroup = state.userGroups[id.value];

            

            if (userGroups) {
                // console.warn("User Group is not empty");
                delete state.userGroups[id.value];
            }
        },
    },
});

export const userManagementActions = userManagementSlice.actions;

export default userManagementSlice.reducer;
