import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BackupData } from "../../model/CarbonExternalModels";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

interface IState {
    backups: BackupData[];
}

const initialState: IState = {
    backups: []
};

export const backupsSlice = createSlice({
    name: "backupsSlice",
    initialState,
    reducers: {
        // Internal Management
        setBackups: (state, action: PayloadAction<BackupData[]>) => {
            state.backups = action.payload;
        },
        addOrUpdateBackup: (state, action: PayloadAction<BackupData>) => {
            const backup: BackupData = action.payload;

            const foundIndex = state.backups.findIndex((b) => b.id.value === backup.id.value);

            if (foundIndex >= 0) {
                state.backups[foundIndex] = backup;
            } else {
                state.backups.push(backup);
            }
        },
        removeBackup: (state, action: PayloadAction<StringID>) => {
            const id: StringID = action.payload;

            const foundIndex = state.backups.findIndex((b) => b.id.value === id.value);

            if (foundIndex >= 0) {
                state.backups.splice(foundIndex, 1)
            }
        },

    },
});

export const BackupsActions = backupsSlice.actions;

export default backupsSlice.reducer;
