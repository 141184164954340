import { faPlus, faPenToSquare, faMultiply, faCircleExclamation, faCheck, faMinus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, Offcanvas, Modal } from "react-bootstrap";
import { objectEqual } from "../../common/functions/objectComparison";
import { CreateClusterBackup } from "../../model/CarbonExternalModels";
import { initCreateClusterBackup } from "../../store/constellations/constellationsUtils";
import ClustersDropdownSelector from "../clusters/cluster/clustersDropdown/ClustersDropdownSelector";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { apiCreateBackup } from "../../messages/api/gcmAPICallers";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";

interface IProps {
    type: "PRIMARY" | "SECONDARY" | "DROPDOWN";
}

export default function CreateBackupAndRestore(props: IProps) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [registerChange, setRegisterChange] = useState<boolean>(false);

    const [originalBackup, setOriginalBackup] = useState<CreateClusterBackup>(initCreateClusterBackup());
    const [currentBackup, setCurrentBackup] = useState<CreateClusterBackup>(originalBackup);

    const selectAll = currentBackup.supports.supportsAccount && currentBackup.supports.supportsApp && currentBackup.supports.supportsMedia;
    const atLeastOneSelected = currentBackup.supports.supportsAccount || currentBackup.supports.supportsApp || currentBackup.supports.supportsMedia;
    const atLeastOneClusterSelected = currentBackup.id && currentBackup.id.value !== "NOT_SET";

    const foundChanges = !objectEqual(originalBackup, currentBackup);
    const typeError = !atLeastOneSelected ? "Please make sure to select at least one backup type." : "";
    const systemError = !atLeastOneSelected ? "Please make sure to select at least one cluster." : "";
    const clusterError = !atLeastOneClusterSelected ? "Please make sure to select at least one cluster." : "";
    const nameError = currentBackup.name.length === 0 ? "Please make sure the name is unique and is not empty." : ""

    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    let canCreate = !typeError && !nameError && !systemError && !clusterError;

    function handleClusterClick(clusterID: StringID) {
        setRegisterChange(true);
        setCurrentBackup({ ...currentBackup, id: clusterID });
    }
    function handleNameChange(event) {
        setRegisterChange(true);
        setCurrentBackup({ ...currentBackup, name: event.target.value });
    }
    function toggleSelectAll(event) {
        if (selectAll || atLeastOneSelected) {
            setCurrentBackup({ ...currentBackup, supports: { supportsAccount: false, supportsApp: false, supportsMedia: false } })
        } else {
            setCurrentBackup({ ...currentBackup, supports: { supportsAccount: true, supportsApp: true, supportsMedia: true } });
        }
    }

    const onFormShow = () => {
        setShowForm(true);
    };
    const onFormHide = () => {
        if (foundChanges) {
            setShowConfirmation(true);
        } else {
            onRestore();
            setShowForm(false);
        }
    };
    const onRestore = () => {
        setRegisterChange(false);
        setCurrentBackup(initCreateClusterBackup());
    };

    const onCreate = () => {

        apiCreateBackup(getGlobalStates().keycloak.token, currentBackup);

        onRestore();
        setShowForm(false);
    };

    if (!permissions.isClusterSetup) return null;

    return (
        <>
            {props.type === "PRIMARY" && (
                <button className="createButton" onClick={(e) => onFormShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                    Create New Backup
                </button>
            )}
            {props.type === "SECONDARY" && (
                <button className="secondaryButton" onClick={(e) => onFormShow()}>
                    Create New Backup
                </button>
            )}
            {props.type === "DROPDOWN" && (
                <Dropdown.Item className="dropdownItem" onClick={(e) => onFormShow()}>
                    <div className="iconInside">
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </div>
                    <div className="textInside">Edit</div>
                </Dropdown.Item>
            )}

            <Offcanvas show={showForm} onHide={onFormHide} placement="end">
                <div className="userfulFormBody">
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">
                                {false ? "Edit a Backup" : "Create a Backup"}
                            </h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">
                                Create a backup and name it descriptively, specifying the contents. The backup will be created with current timestamp.
                            </h3>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">Backup Details</h4>
                            <h5 className="userfulFormContentsDescription">
                                Name and select what should be included in this backup.
                            </h5>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Name</h5>
                                <h6>{currentBackup.name.length}/60</h6>
                            </div>
                            <input
                                className="userfulInputField"
                                placeholder="Enter name here..."
                                type="text"
                                maxLength={60}
                                value={currentBackup.name}
                                onChange={handleNameChange}
                            />
                            {nameError && registerChange && <div className="errorText">{nameError}</div>}
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">Include Media</h4>
                            <h5 className="userfulFormContentsDescription">
                                Should this backup include media files, this will make the size of the backup file substantially larger.
                            </h5>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="compositeContainer">
                                {/* <div className={`fieldRowWrapper `} onClick={toggleSelectAll} style={{ cursor: "pointer" }}>
                                    <div className={`checkbox ${selectAll || atLeastOneSelected ? "checked" : null} `}>
                                        {selectAll ? <FontAwesomeIcon icon={faCheck} /> : null}
                                        {atLeastOneSelected && !selectAll ? <FontAwesomeIcon icon={faMinus} /> : null}
                                    </div>
                                    <div className="fieldColumnWrapper shortGap">
                                        <div className="mainText">Select All</div>
                                    </div>
                                </div>
                                <div
                                    className={`fieldRowWrapper `}
                                    onClick={(e) => {
                                        setCurrentBackup({ ...currentBackup, supports: { ...currentBackup.supports, supportsAccount: !currentBackup.supports.supportsAccount } })
                                    }}
                                    style={{ cursor: "pointer", marginLeft: 32 }}
                                >
                                    <div className={`checkbox ${currentBackup.supports.supportsAccount ? "checked" : null} `}>
                                        {currentBackup.supports.supportsAccount ? <FontAwesomeIcon icon={faCheck} /> : null}

                                    </div>
                                    <div className="fieldColumnWrapper shortGap">
                                        <div className="mainText">Accounts Data</div>
                                    </div>
                                </div>
                                <div
                                    className={`fieldRowWrapper `}
                                    onClick={(e) => {
                                        setCurrentBackup({ ...currentBackup, supports: { ...currentBackup.supports, supportsApp: !currentBackup.supports.supportsApp } })
                                    }}
                                    style={{ cursor: "pointer", marginLeft: 32 }}
                                >
                                    <div className={`checkbox ${currentBackup.supports.supportsApp ? "checked" : null} `}>
                                        {currentBackup.supports.supportsApp ? <FontAwesomeIcon icon={faCheck} /> : null}
                                    </div>
                                    <div className="fieldColumnWrapper shortGap">
                                        <div className="mainText">Apps Data</div>
                                    </div>
                                </div> */}
                                <div
                                    className={`fieldRowWrapper `}
                                    onClick={(e) => {
                                        setCurrentBackup({ ...currentBackup, supports: { ...currentBackup.supports, supportsMedia: !currentBackup.supports.supportsMedia } })
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className={`checkbox ${currentBackup.supports.supportsMedia ? "checked" : null} `}>
                                        {currentBackup.supports.supportsMedia ? <FontAwesomeIcon icon={faCheck} /> : null}

                                    </div>
                                    <div className="fieldColumnWrapper shortGap">
                                        <div className="mainText">Media Files</div>
                                    </div>
                                </div>
                                {typeError && registerChange && <div className="errorText">{typeError}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">System</h4>
                            <h5 className="userfulFormContentsDescription">
                                Select which system you would like to create a backup of.
                            </h5>
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Cluster</h5>
                            </div>
                            <ClustersDropdownSelector clusterID={currentBackup.id} handleClick={handleClusterClick} />
                            {clusterError && registerChange && <div className="errorText">{clusterError}</div>}
                        </div>
                    </div>
                </div>
                <div className="userfulFormFooter">
                    <button className="secondaryUserfulButton" onClick={onFormHide}>
                        Cancel
                    </button>
                    <button
                        className="primaryUserfulButton"
                        onClick={onCreate}
                        disabled={!canCreate}
                    >
                        {false ? "Save Changes" : "Create"}
                    </button>
                </div>
            </Offcanvas>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Are you sure you want to leave?</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            Are you sure you want to leave without saving your changes? Any unsaved modifications will
                            be lost.
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowForm(false);
                                setShowConfirmation(false);
                                onRestore();
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
