import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from "react-redux";
import appSlice from "./app/appSlice";
import constellationsSlice from "./constellations/constellationsSlice";
import filtersSlice from "./filters/filtersSlice";
import userManagementSlice from "./userManagement/userManagementSlice";
import backupSlice from "./backup/backupSlice";

const carbonStore = configureStore({
    reducer: {
        app: appSlice,
        constellations: constellationsSlice,
        filters: filtersSlice,
        backup: backupSlice,
        userManagement: userManagementSlice,
    },
});
export default carbonStore;

export const CarbonContext = React.createContext(null);

export type RootState = ReturnType<typeof carbonStore.getState>;
export type AppDispatch = typeof carbonStore.dispatch;
export const useCarbonSelector: TypedUseSelectorHook<RootState> = createSelectorHook(CarbonContext);
export const useCarbonDispatch = createDispatchHook<AppDispatch>(CarbonContext);

// App Selectors
export const selectAppTenant = (state: RootState) => state.app.tenant;
export const selectUserPermissions = (state: RootState) => state.app.permission;
export const selectAppView = (state: RootState) => state.app.appView;
export const selectAppStatus = (state: RootState) => state.app.appStatus;
export const selectAppNavigationStatus = (state: RootState) => state.app.navigationStatus;
export const selectClusterView = (state: RootState) => state.app.clusterView;
export const selectPairingCodeConsumed = (state: RootState) => state.app.consumedCode;
export const selectClusterTabsView = (state: RootState) => state.app.clusterTabsView;

// Backups
export const selectBackups = (state: RootState) => state.backup.backups;

// Filters Selectors
export const selectConstellationFilters = (state: RootState) => state.filters.constellations;
export const selectUserFilters = (state: RootState) => state.filters.users;
export const selectUserGroupFilters = (state: RootState) => state.filters.userGroups;
export const selectClusterFilters = (state: RootState) => state.filters.clusters;
export const selectDevicesFilters = (state: RootState) => state.filters.devices;
export const selectBackupAndRestoreFilters = (state: RootState) => state.filters.backupAndRestore;
export const selectFiltersVisibility = (state: RootState) => state.filters.visibility;
export const selectSearchInput = (state: RootState) => state.filters.search;

// Constellations Selectors
export const selectConstellations = (state: RootState) => state.constellations.constellations;
export const selectPairingCode = (state: RootState) => state.constellations.pairingCode;
export const selectDashboards = (state: RootState) => state.constellations.dashboards;

// UserManagement Selectors
export const selectUsers = (state: RootState) => state.userManagement.users;
export const selectUserGroups = (state: RootState) => state.userManagement.userGroups;
export const selectRoles = (state: RootState) => state.userManagement.roles;
export const selectUserGroupTab = (state: RootState) => state.app.userGroupTab;
