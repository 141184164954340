import { faTrashAlt, faCircleExclamation, faMultiply } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { BackupData } from "../../model/CarbonExternalModels";
import { Button, UserfulTooltip } from "userful-chronos-common-ui/dist";
import { deleteBackup } from "../../store/backup/backupManager";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

interface IProps {
    backup: BackupData;
}

export default function BackupDelete(props: IProps) {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const permissions: GcmUserPermission = getGlobalStates().gcmUserPermission;

    if (!permissions.isClusterSuppressor) return null;

    return (
        <>

            <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                    setShowConfirmation(true);
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
                <div className="textInside">Delete</div>
            </Dropdown.Item>

            {/* <UserfulTooltip title={"Delete"} >
                <Button variant="ghost" onClick={() => {
                    setShowConfirmation(true);
                }}> <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </UserfulTooltip> */}

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Delete Backup</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            {`Are you sure you want to delete ${props.backup.metadata.name} ?`}
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                                deleteBackup(props.backup, "INTERNAL");
                            }}
                        >
                            Delete
                        </button>

                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
