import React, { ReactNode } from "react";
import Footer from "../Footer/Footer";
import NavBar from "../Header/NavBar";
import DisconnectOverlay from "../Widgets/DisconnectOverlay/DisconnectOverlay";

import "./UserfulColors.css";
import "./UserfulSizes.css";

import "./UserfulPageLayout.scss";
import "../I18nextConfig";
import ErrorOverlay from "../Error/ErrorOverlay";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import NotificationsContainer from "./NotificationsContainer";
import ConfirmationToast from "./ConfirmationToast";
import { UserPermissionType } from 'userful-chronos-app-common-js/dist/models/user-permission'
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import AppAccessDenied from "./AppAccessDenied";
import { getRootPath } from "userful-chronos-app-common-js/dist/routing";
import { ChoppedAssetsProvider } from "userful-chronos-common-store/dist/sourceassets";

interface IProps {
     viewPermissions?: UserPermissionType[],
     showNotification?: boolean,
     showErrors?: boolean,
     hideDisconnectOverlay?: boolean;
     showFooter?: boolean;
     children: ReactNode,
     className?: string,
     version?: string;
     hideNavigation?: boolean;
}

const checkPermission = (viewPermissions?: UserPermissionType[]): boolean => {
     if (!viewPermissions || viewPermissions.length < 1) {
          return true;
     }
     const userPermissions = getGlobalStates().userPermission;
     for (const check of viewPermissions) {
          if (userPermissions && userPermissions[check]) {
               return true;
          }
     }
     return false;
}

const UserfulPageLayout = (props: IProps & React.ComponentProps<typeof NavBar>) => {
     const showNotification = (props.showNotification !== false);
     const showErrors = (props.showErrors !== false);

     const canView = checkPermission(props.viewPermissions);
     if (!canView) {
          window.location.replace(getRootPath())
     }

     const inner = () => <>
          {!props.hideNavigation && <NavBar {...props} />}
          <DisconnectOverlay disabled={props.hideDisconnectOverlay} />
          {canView ?
               <>
                    {showErrors && <ErrorOverlay />}
                    <div
                         style={{ minHeight: props.showFooter ? "calc(100vh - 105px) !important" : "calc(100vh - 57px) !important" }}
                         className={props.className || ''}
                    >
                         {props.children}
                    </div>
                    {props.showFooter && <Footer version={props.version} />}
                    <ToastContainer enableMultiContainer transition={Bounce} />
                    <ToastContainer enableMultiContainer transition={Bounce} containerId="uploadFileContainer" />
                    {showNotification &&
                         <NotificationsContainer />
                    }
                    <ConfirmationToast />
               </>
               :
               <div
                    style={{
                         height: props.showFooter ? "calc(100vh - 105px)" : "calc(100vh - 57px)"
                    }}
                    className={props.className || ''}
               >
                    <AppAccessDenied />
               </div>
          }
     </>

     if (canView && showNotification) {
          return <ChoppedAssetsProvider>
               {inner()}
          </ChoppedAssetsProvider>
     }

     return inner();
};

export default UserfulPageLayout;
