import { faAngleDown, faCircleNodes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { IClusterInfo } from "../../../../model/CarbonExternalModels";
import { selectConstellations, useCarbonSelector } from "../../../../store/carbonStore";
import { Button } from "userful-chronos-common-ui/dist";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

interface IProps {
    clusterID: StringID;
    handleClick(clusterID: StringID): void;
}

export default function ClustersDropdownSelector({ clusterID, handleClick }: IProps) {

    const constellations = useCarbonSelector(selectConstellations);
    const allClusters: IClusterInfo[] = [];

    for (const property in constellations) {
        for (let i = 0; i < constellations[property].cluster.length; i++) {
            if (constellations[property].cluster[i].name !== "N/A") {
                allClusters.push(constellations[property].cluster[i])
            }
        }
    }

    const cluster = allClusters.find((c) => c.id.value === clusterID.value);

    return (
        <Dropdown className="dropdown-input">
            <Dropdown.Toggle
                bsPrefix="dropdown-input-toggle"
                as={Button}
                id="dropdownToggle"
            >
                <div className="textInside">{cluster ? cluster.name : "Select a cluster..."}</div>
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdown-input-menu"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                {allClusters.length > 0 && (
                    <>
                        {allClusters.map((c) => {
                            return (
                                <Dropdown.Item
                                    key={c.id.value}
                                    className="dropdownItem"
                                    onClick={() => {
                                        handleClick(c.id)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCircleNodes} />
                                    <div className="textInside">{c.name}</div>
                                </Dropdown.Item>
                            );
                        })}
                    </>
                )}
                {allClusters.length === 0 && <Dropdown.Item
                    className="dropdownItem"
                    disabled
                >
                    <FontAwesomeIcon icon={faCircleNodes} />
                    <div className="textInside">No clusters available...</div>
                </Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    );
}
