import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { getFederationDataFromServer, addFederationData, updateFederationData, deleteFederationData, syncFederationData } from './msgs/ldapAPICallers';
import { UserFederationData, LdapUserFederation, AddLdapUserFederation, UpdateLdapUserFederation, UserFederationID, SyncLdapUser } from 'userful-chronos-app-common-js/dist/models/usermgt/ldap';
import { initLDAPUserFederation } from 'userful-chronos-app-common-js/dist/models/usermgt/ldapUI';

export interface InitialStateLDAP {
    federationData: UserFederationData;
    loadBool: boolean;
    saveBool: boolean;
    overlay: boolean;
    blockUI: boolean;
}

const initialStateLDAP: InitialStateLDAP = {
    federationData: { ldaps: [], ldapConfigData:null },
    loadBool: true,
    saveBool:false,
    overlay: false,
    blockUI: false,
};

export const ldapSlice = createSlice({
    name: 'ldapSlice',
    initialState: initialStateLDAP,
    reducers: {
        getFederationDataFromServer: (state, action: PayloadAction<{}>) => {
            getFederationDataFromServer(getGlobalStates().keycloak.token);
        },
        setFederationData: (state, action: PayloadAction<UserFederationData>) => {
            state.federationData = action.payload;
        },
        setLoadBool: (state, action: PayloadAction<boolean>) => {
            state.loadBool = action.payload;
        },
        addFederationData: (state, action: PayloadAction<AddLdapUserFederation>) => {
            state.saveBool = true;
            state.blockUI = true;
            addFederationData(getGlobalStates().keycloak.token, action.payload);
        },
        updateFederationData: (state, action: PayloadAction<UpdateLdapUserFederation>) => {
            state.blockUI = true;
            state.saveBool=true;
            updateFederationData(getGlobalStates().keycloak.token, action.payload);
        },
        deleteFederationData: (state, action: PayloadAction<UserFederationID>) => {
            state.blockUI = true;
            state.saveBool = true;
            deleteFederationData(getGlobalStates().keycloak.token, action.payload);
        },
        syncFederationData: (state, action: PayloadAction<SyncLdapUser>) => {
            state.blockUI = true;
            state.saveBool = true;
            syncFederationData(getGlobalStates().keycloak.token, action.payload);
        },
        setSaveBool: (state, action: PayloadAction<boolean>) => {
            state.saveBool = action.payload;
        },
        setOverlay: (state, action: PayloadAction<boolean>) => {
            state.overlay = action.payload;
        },
        setBlockUI: (state, action: PayloadAction<boolean>) => {
            state.blockUI = action.payload;
        }

    }
})

export const ldapSliceActions = ldapSlice.actions;

export default ldapSlice.reducer;