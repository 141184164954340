import { faCircleExclamation, faMultiply, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { KeycloakUser } from "../../../model/CarbonExternalModels";
import { deleteUser } from "../../../store/userManagement/userManagementManager";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    user: KeycloakUser;
    permissions: UserPermission;
}

export default function UserDelete(props: IProps) {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    if (props.user.userProfile.userName === "admin" || !props.permissions.isUserSuppressor) return null;

    return (
        <>
            <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                    setShowConfirmation(true);
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
                <div className="textInside">Delete</div>
            </Dropdown.Item>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Delete Permission Set</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            {`Are you sure you want to delete ${props.user.userProfile.userName} ?`}
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                                deleteUser(props.user.id, "INTERNAL");
                            }}
                        >
                            Delete
                        </button>

                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
