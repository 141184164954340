
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import "./ClustersCard.scss";
import { IClusterInfo, IConstellationInfo } from "../../../model/CarbonExternalModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleNodes, faCodeBranch, faCodeFork, faCube, faDisplay, faExternalLink, faFileCertificate, faGlobe, faHardDrive, faMicrochip, faMultiply, faRefresh, faServer } from "@fortawesome/pro-regular-svg-icons";
import { getGPUUsage, getCPUUsage } from "../../../common/functions/formaters";
import { UserfulTooltip } from 'userful-chronos-common-ui/dist';

interface IProps {
    foundObject: { cluster: IClusterInfo; constellation: IConstellationInfo };
    onRowClick?(id: StringID): void;
}

export default function ClustersCard({ foundObject, onRowClick }: IProps) {

    let totalUClients = 0;
    let totalGPULoad = 0;
    let totalCPUUsage = 0;

    let averageGPULoad = 0;
    let averageCPUUsage = 0;

    for (let i = 0; i < foundObject.cluster.clusterDeviceInfo.length; i++) {
        const clusterDeviceInfo = foundObject.cluster.clusterDeviceInfo[i];

        totalUClients += clusterDeviceInfo.endpoint.offline + clusterDeviceInfo.endpoint.online;
        totalGPULoad += getGPUUsage(clusterDeviceInfo.deviceUsage.gpu);
        totalCPUUsage += getCPUUsage(clusterDeviceInfo.deviceUsage.cpu);
    }

    averageGPULoad = Math.round(totalGPULoad / foundObject.cluster.clusterDeviceInfo.length);
    averageCPUUsage = Math.round(totalCPUUsage / foundObject.cluster.clusterDeviceInfo.length);


    return (
        <div className="clustersCard" onClick={(e) => onRowClick(foundObject.cluster.id)}>
            <div className="clusterCardHeader">
                <div className="clusterCardSubHeader">
                    <FontAwesomeIcon icon={faCircleNodes} />
                    <p>{foundObject.cluster.name}</p>
                </div>
                {foundObject.cluster.ip.length >= 1 &&
                    <UserfulTooltip title="Go to">
                        <a onClick={(e) => { e.stopPropagation() }} target="_blank" href={foundObject.cluster.accessConfig.accessUrl} className="buttonLink"><FontAwesomeIcon icon={faExternalLink} /></a>
                    </UserfulTooltip>
                }
            </div>
            <div className="clusterCardBody">
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faGlobe} />
                        <p>IP Address</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.ip.length >= 1 ? foundObject.cluster.ip : "Offline"}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faCodeBranch} />
                        <p>Version</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>Userful {foundObject.cluster.version.length >= 1 ? foundObject.cluster.version : "X.X"}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faFileCertificate} />
                        <p>License Status</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        {foundObject.cluster.license.active && <div><FontAwesomeIcon className="green" icon={faCheck} /></div>}
                        {!foundObject.cluster.license.active && <div><FontAwesomeIcon className="red" icon={faMultiply} /></div>}
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faRefresh} />
                        <p>Failover Status</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        {foundObject.cluster.license.failOver && <div><FontAwesomeIcon className="green" icon={faCheck} /></div>}
                        {!foundObject.cluster.license.failOver && <div><FontAwesomeIcon className="red" icon={faMultiply} /></div>}
                    </div>
                </div>
            </div>
            <div className="clusterCardBody">
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faCodeFork} />
                        <p>Total Mapping Groups</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.mappingGroups}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faServer} />
                        <p>Total Devices</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.clusterDeviceInfo.length}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faHardDrive} />
                        <p>Total uClient Players</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{totalUClients}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faCube} />
                        <p>Total Active Sources</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.activeSources}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faDisplay} />
                        <p>Total Active Destinations</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.activeDestinations}</p>
                    </div>
                </div>
                {/* <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faMicrochip} />
                        <p>Total GPUs</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.clusterDeviceInfo.length}</p>
                    </div>
                </div>
                <div className="clusterCardBodyRow">
                    <div className="clusterCardBodyColumn">
                        <FontAwesomeIcon icon={faMicrochip} />
                        <p>Total CPUs</p>
                    </div>
                    <div className="clusterCardBodyColumn">
                        <p>{foundObject.cluster.clusterDeviceInfo.length}</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
