import { faMultiply, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { selectPairingCode, useCarbonDispatch, useCarbonSelector } from "../../store/carbonStore";
import { faInfoCircle, faClone } from "@fortawesome/pro-regular-svg-icons";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { IConstellationInfo } from "../../model/CarbonExternalModels";
import { getPairingCode } from '../../messages/api/gcmAPICallers';
import { ConstellationsActions } from "../../store/constellations/constellationsSlice";

interface IProps {
    type: "PRIMARY" | "SECONDARY";
    constellation: IConstellationInfo;
}

type TPairingStatus = "LOADING" | "IDLE";

export default function PairingCode(props: IProps) {
    const pairingCode = useCarbonSelector(selectPairingCode);

    const dispatch = useCarbonDispatch()

    const url = window.location.href.split("/#")[0];

    let pairingTimeout;
    let urlTimeout;

    const [showForm, setShowForm] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);

    const [copiedCode, setCopiedCode] = useState(false);
    const [copiedURL, setCopiedURL] = useState(false);

    // Pairing status:
    const [pairingStatus, setPairingStatus] = useState<TPairingStatus>("IDLE");

    function handleChange(event) {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);

        if (enteredEmail.length > 0) {
            // Regular expression for email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(enteredEmail);
            setIsValid(isValidEmail);
        } else setIsValid(true);
    }
    const onFormShow = () => {
        setShowForm(true);
    };
    const onFormHide = () => {
        setShowForm(false);
        dispatch(ConstellationsActions.setPairingCode(""));
    };
    const onFormGenerateCode = () => {
        setPairingStatus("LOADING");

        getPairingCode(getGlobalStates().keycloak.token, props.constellation.definition.id);
    };

    function codeToClipboard() {
        const textToCopy = pairingCode;

        // Create a temporary input element
        const tempInput = document.createElement("input");

        // Set the value of the input
        tempInput.value = textToCopy;

        // Append the input element to the document
        document.body.appendChild(tempInput);

        // Select the text in the input
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        clearTimeout(pairingCode);

        setCopiedCode(true);
        pairingTimeout = setTimeout(() => {
            setCopiedCode(false);
        }, 2000);
    }

    function urlToClipboard() {
        const textToCopy = url;

        // Create a temporary input element
        const tempInput = document.createElement("input");

        // Set the value of the input
        tempInput.value = textToCopy;

        // Append the input element to the document
        document.body.appendChild(tempInput);

        // Select the text in the input
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        clearTimeout(urlTimeout);

        setCopiedURL(true);
        urlTimeout = setTimeout(() => {
            setCopiedURL(false);
        }, 2000);
    }

    useEffect(() => {
        setPairingStatus("IDLE");
    }, [pairingCode]);

    const beginPairingAllowed = isValid;
    const pairingCodePretty = pairingCode.slice(0, 4) + "-" + pairingCode.slice(4);

    return (
        <>
            {props.type === "PRIMARY" && (
                <button className="createButton" onClick={(e) => onFormShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                    Generate Pairing Code
                </button>
            )}
            {props.type === "SECONDARY" && (
                <button className="secondaryButton" onClick={(e) => onFormShow()}>
                    Generate Pairing Code
                </button>
            )}

            <Offcanvas show={showForm} onHide={onFormHide} placement="end">
                <div className="userfulFormBody">
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">Generate Pairing Code</h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">
                                Pair a Userful System to Userful’s Global Cluster Manager.
                            </h3>
                        </div>
                    </div>
                    {pairingCode.length === 0 && (
                        <div className="userfulFormContents">
                            <div className="userfulFormColumn userfulFormSmallGap">
                                <h4 className="userfulFormContentsTitle">Send Pairing Code</h4>
                                <h5 className="userfulFormContentsDescription">
                                    The generated pairing code will be sent to the following email addresses in addition
                                    to the Global Cluster Manager Administrator email.
                                </h5>
                            </div>
                            <div className="userfulFormColumn userfulFormNormalGap">
                                <div className="userfulFormFieldTitle">
                                    <h5>Email Addresses (Optional)</h5>
                                </div>
                                <input
                                    className="userfulInputField"
                                    style={{ borderColor: isValid ? null : "red" }}
                                    placeholder="Enter email here..."
                                    type="email"
                                    value={email}
                                    onChange={handleChange}
                                />
                                {!isValid && <p style={{ color: "red" }}>Please enter a valid email address.</p>}
                            </div>
                        </div>
                    )}
                    {pairingCode.length > 0 && (
                        <div className="userfulFormContents">
                            <div className="userfulFormColumn userfulFormSmallGap">
                                <h4 className="userfulFormContentsTitle">Pairing Code</h4>
                                <h5 className="userfulFormContentsDescription">
                                    Use this pairing code to pair a Userful server to the Global Cluster Manager. This
                                    pairing code will expire in 24hrs.
                                </h5>
                            </div>
                            <div className="userfulFormColumn userfulFormNormalGap">
                                <div className="userfulFormFieldTitle">
                                    <h5>Pairing Code</h5>
                                </div>
                                <div className="userfulInputToCopy">
                                    <p>{pairingCodePretty}</p>
                                    {copiedCode ? (
                                        <UserfulTooltip title={"Copied"}>
                                            <button onClick={(e) => codeToClipboard()}>
                                                <FontAwesomeIcon icon={faClone} />
                                            </button>
                                        </UserfulTooltip>
                                    ) : (
                                        <button onClick={(e) => codeToClipboard()}>
                                            <FontAwesomeIcon icon={faClone} />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="userfulFormColumn userfulFormNormalGap">
                                <div className="userfulFormFieldTitle">
                                    <h5>URL</h5>
                                </div>
                                <div className="userfulInputToCopy">
                                    <p>{url}</p>
                                    {copiedURL ? (
                                        <UserfulTooltip title={"Copied"}>
                                            <button onClick={(e) => urlToClipboard()}>
                                                <FontAwesomeIcon icon={faClone} />
                                            </button>
                                        </UserfulTooltip>
                                    ) : (
                                        <button onClick={(e) => urlToClipboard()}>
                                            <FontAwesomeIcon icon={faClone} />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="userfulFormInfoBlock">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <p className="infoText">
                                    To pair a Userful System with the Global Cluster Manager, follow these steps: <br />
                                    1. Using the pairing code generated above. <br />
                                    2. Sign into the Userful System that you want to pair, navigate to Admin
                                    {"Center > System Configuration > General Settings"}, click the pairing button and
                                    paste in the code.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                {pairingCode.length === 0 && (
                    <div className="userfulFormFooter">
                        <button className="secondaryUserfulButton" onClick={onFormHide}>
                            Cancel
                        </button>
                        <button
                            className="primaryUserfulButton"
                            disabled={!beginPairingAllowed || pairingStatus === "LOADING"}
                            onClick={onFormGenerateCode}
                        >
                            {pairingStatus === "LOADING" && <div className="spinner" />}Generate Pairing Code
                        </button>
                    </div>
                )}
            </Offcanvas>
        </>
    );
}
