import React from "react";
import { faCircleExclamation, faMultiply, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal } from "react-bootstrap";
import { useState } from "react";
import { KeycloakGroup } from "../../../model/CarbonExternalModels";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { useCarbonDispatch } from "../../../store/carbonStore";
import { deleteUserGroup } from "../../../store/userManagement/userManagementManager";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    group: KeycloakGroup;
    type: "DROPDOWN" | "ICON";
    permissions: UserPermission;
}

export default function UserGroupDelete(props: IProps) {
    const dispatch = useCarbonDispatch();

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    if (!props.permissions.isUserSuppressor) return null;

    return (
        <>
            {props.type === "DROPDOWN" ? (
                <Dropdown.Item
                    className="dropdownItem"
                    onClick={() => {
                        setShowConfirmation(true);
                    }}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    <div className="textInside">Delete</div>
                </Dropdown.Item>
            ) : (
                <UserfulTooltip title={"Delete"}>
                    <button
                        className="iconButton"
                        onClick={() => {
                            setShowConfirmation(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                </UserfulTooltip>
            )}

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Delete Permission Set</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">{`Are you sure you want to delete ${props.group.name} ?`}</div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);

                                deleteUserGroup(props.group.id, "INTERNAL");
                            }}
                        >
                            Delete
                        </button>

                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
