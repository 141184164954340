import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import { selectAppNavigationStatus, useCarbonDispatch, useCarbonSelector } from "../../store/carbonStore";

import "./Header.scss";

import screenfull from "screenfull";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faBars, faChevronRight, faExpand } from "@fortawesome/pro-regular-svg-icons";
import { TNavigationStatus } from "../../store/app/appUtils";
import { AppSliceActions } from "../../store/app/appSlice";
import { Button, UserfulTooltip } from "userful-chronos-common-ui/dist";
import { Dropdown } from "react-bootstrap";
import { useAuthenticationContext } from "../../auth/AuthenticationContext";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

interface IProps {}

export default function Header(props: IProps) {
    const dispatch = useCarbonDispatch();

    const { logout } = useAuthenticationContext();

    const navigationStatus: TNavigationStatus = useCarbonSelector(selectAppNavigationStatus);

    const clientId = getGlobalStates().keycloak.tokenParsed.preferred_username;

    const toggleFullScreen = () => {
        screenfull.toggle();
    };

    const logoutAndRefreshPage = () => {
        logout();
        localStorage.removeItem("authData");
    };

    return (
        <header className="userfulHeader">
            <div className="cluster normalGap">
                <UserfulTooltip placement="bottom" title="Toggle Navigation">
                    <button
                        className="iconButton"
                        onClick={(e) => {
                            if (navigationStatus === "SHOWN") dispatch(AppSliceActions.setNavigationStatus("HIDDEN"));
                            else dispatch(AppSliceActions.setNavigationStatus("SHOWN"));
                        }}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </UserfulTooltip>
                <img src="./images/userful-secondary-black.svg" alt="carbon" />
                <FontAwesomeIcon icon={faChevronRight} />
                <h1>Global Cluster Manager</h1>
            </div>
            <div className="cluster normalGap">
                <UserfulTooltip placement="bottom" title="Fullscreen">
                    <button className="iconButton" onClick={() => toggleFullScreen()}>
                        <FontAwesomeIcon icon={faExpand} />
                    </button>
                </UserfulTooltip>
                <Dropdown className="operationCenterDropdown">
                    <Dropdown.Toggle bsPrefix="account" id="dropdownToggle" as={Button}>
                        <p>{clientId && clientId[0].toUpperCase()}</p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className="dropdownMenuSwitch"
                        style={{ width: 240, zIndex: 200 }}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyUp={(e) => e.stopPropagation()}
                    >
                        <Dropdown.Item className="dropdownItem" disabled>
                            <div className="iconInside">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="textInside">{clientId}</div>
                        </Dropdown.Item>
                        <hr />
                        <Dropdown.Item
                            className="dropdownItem"
                            onClick={() => {
                                logoutAndRefreshPage();
                            }}
                        >
                            <div className="iconInside">
                                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                            </div>
                            <div className="textInside">Logout</div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </header>
    );
}
