import {
    faKey,
    faMultiply,
    faEyeSlash,
    faEye,
    faCircleExclamation,
    faCircleQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Offcanvas, Modal } from "react-bootstrap";
import { KeycloakUser, KeycloakUserProfileUserIDPassword } from "../../../model/CarbonExternalModels";
import { isPasswordValid } from "../../../common/functions/password";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { apiPWDUser } from "../../../messages/api/gcmUserManagement";
import { UserPermission } from "../../../model/CarbonPermissionModels";

interface IProps {
    user: KeycloakUser;
    permissions: UserPermission;
}

export default function UserPassword(props: IProps) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const foundChanges = password.length > 0 || confirmPassword.length > 0;
    const passwordError = isPasswordValid(password) ? "" : "Please make sure that password satisfies requirements.";
    const confirmPasswordError = password === confirmPassword ? "" : "Please make sure that passwords match.";

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }
    function toggleShowPassword(event) {
        setShowPassword(!showPassword);
    }
    function handleConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value);
    }
    function toggleShowConfirmPassword(event) {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const onFormShow = () => {
        setShowForm(true);
    };
    const onFormHide = () => {
        if (foundChanges) {
            setShowConfirmation(true);
        } else {
            setShowForm(false);
        }
    };
    const onRestore = () => {
        setPassword("");
    };
    const onConfirm = (e) => {
        const keycloakUserProfileUserIDPassword: KeycloakUserProfileUserIDPassword = {
            userProfile: props.user.userProfile,
            userIDPassword: {
                id: props.user.id,
                password: {
                    password: password,
                    temporary: false,
                },
            },
        };
        apiPWDUser(getGlobalStates().keycloak.token, keycloakUserProfileUserIDPassword);
        onRestore();
        setShowForm(false);
    };

    const canUpdate = passwordError.length === 0 && confirmPasswordError.length === 0 && foundChanges;

    if (!props.permissions.isUserCreator) return null;

    return (
        <>
            <Dropdown.Item className="dropdownItem" onClick={(e) => onFormShow()}>
                <FontAwesomeIcon icon={faKey} />
                <div className="textInside">Reset Password</div>
            </Dropdown.Item>

            <Offcanvas show={showForm} onHide={onFormHide} placement="end">
                <div className="userfulFormBody noselect">
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">Reset Password</h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">
                                Make sure that user has a unique username and a safe password.
                            </h3>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">User Details</h4>
                            <h5 className="userfulFormContentsDescription">Details relating to this user.</h5>
                        </div>

                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <div className="cluster shortGap">
                                    <h5>Password</h5>
                                    <UserfulTooltip title="Password must be at least 8 characters long, must contain at least one uppercase letter, must contain at least one lowercase letter, at least one number, and at least one special symbol.">
                                        <div>
                                            <FontAwesomeIcon icon={faCircleQuestion} />
                                        </div>
                                    </UserfulTooltip>
                                </div>
                            </div>
                            <div className="passwordContainer">
                                {showPassword ? (
                                    <input
                                        className="userfulInputField"
                                        placeholder="Enter password here..."
                                        type="text"
                                        maxLength={200}
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                ) : (
                                    <input
                                        className="userfulInputField"
                                        placeholder="Enter password here..."
                                        type="password"
                                        maxLength={200}
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                )}
                                <button className="userfulInputFieldButton" onClick={toggleShowPassword}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                            {passwordError && <div className="errorText">{passwordError}</div>}
                        </div>
                        <div className="userfulFormColumn userfulFormNormalGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Confirm Password</h5>
                            </div>
                            <div className="passwordContainer">
                                {showConfirmPassword ? (
                                    <input
                                        className="userfulInputField"
                                        placeholder="Confirm password here..."
                                        type="text"
                                        maxLength={200}
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                ) : (
                                    <input
                                        className="userfulInputField"
                                        placeholder="Confirm password here..."
                                        type="password"
                                        maxLength={200}
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                )}
                                <button className="userfulInputFieldButton" onClick={toggleShowConfirmPassword}>
                                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                            {confirmPasswordError && <div className="errorText">{confirmPasswordError}</div>}
                        </div>
                    </div>
                </div>
                <div className="userfulFormFooter">
                    <button className="secondaryUserfulButton" onClick={onFormHide}>
                        Cancel
                    </button>
                    <button className="primaryUserfulButton" onClick={onConfirm} disabled={!canUpdate}>
                        Change Password
                    </button>
                </div>
            </Offcanvas>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Are you sure you want to leave?</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            Are you sure you want to leave without saving your changes? Any unsaved modifications will
                            be lost.
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowForm(false);
                                setShowConfirmation(false);
                                onRestore();
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
