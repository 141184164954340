import React from "react";
import Button from "./Button/Button";
import Footer from "./Footer/Footer";
import NavBar from "./Header/NavBar";
import UserfulPageLayout from "./Container/UserfulPageLayout";
import UserfulReactTable from "./table/UserfulReactTable";
import UserfulReactTable2 from "./table/v2/UserfulReactTable2";
import CustomToggle from "./table/CustomToggle";
import CustomMenu from "./table/CustomMenu";
import CustomSwitch from "./CustomSwitch/CustomSwitch";
import InitLoading from "./Widgets/InitLoading/InitLoading";
// import Authenticated from './Auth/Authenticated';
import {AuthenticationProvider} from "./Auth2/AuthenticationProvider";
import { useAuthenticationContext } from "userful-chronos-common-store/dist/auth";
import Paginated from "./pagination/Paginated";
import EllipsisAvatars from "./Widgets/EllipsisAvatars/EllipsisAvatars";
import { GlobalModal, MODAL_COMPONENTS, useGlobalModalContext } from "./GlobalModal/GlobalModal";
import { GlobalLoading, LOADING_COMPONENTS, useGlobalLoadingContext } from "./GlobalLoading/GlobalLoading";
import SideNav from "./Sidenav/SideNav";
import Search from "./Search/Search";
import MappingGroupLoading from "./Widgets/MappingGroupLoading/MappingGroupLoading";
import DestinationGroupWidget from "./Widgets/DestinationGroup/DestinationGroupWidget";
import VideoWallWidget from "./Widgets/VideoWall/VideoWallWidget";
import filterArrayBySearchValue, { arrayHasValue, objectHasValue } from "./Search/SearchUtils";
import I18nextConfig from "./I18nextConfig";

import DraggableAndResizable from "./Widgets/DragAndResize/DraggableAndResizable";
import SortableMultiSelect from "./Widgets/SortableMultiSelect/SortableMultiSelect";
import DraggableAndResizablePopup from "./Widgets/DragAndResize/DraggableAndResizablePopup";
import { copyTextToClipboard, trimValue } from "./Utils/UiUtils";
import UserfulTooltip from "./Widgets/UserfulTooltip";

import HelpInfo from "./Widgets/HelpInfo/HelpInfo";
import StaticValueCopyField from "./Widgets/StaticValueCopyField/StaticValueCopyField";

import { AppsConfig } from "./SAM/formUtils";
import { cancelButton, discardChangesButton, saveWithWarningButton } from "./Widgets/Buttons/Presets/buttonPresets";
import CustomSearch from "./table/CustomSearch";
import UserfulSearchBar from "./Components/searchBar/UserfulSearchBar";
import CustomMenuClient from "./Displays/CustomMenuClient";
import CustomToggleClient from "./Displays/CustomToggleClient";
import { ConfirmationToastContent } from "./Container/ConfirmationToastContent";

import { ConfirmToastContainerOptions, customClose } from './Container/ConfirmationToast';

import { NoSourcePlaceholder } from "./sourceassets";
import {  TextWidget, PasswordWidget, FormHeader, FieldHeader} from "./smtp";
import Input from './Input/Input';
import InputPassword from './Input/InputPassword';

import Textarea from './Textfield/Textarea';
import InputNumbers from "./Input/InputNumbers";

const DEST_VIDEO_WALL_URL_PARAMS = {
     currentVideoWall: "currentvideowall",
     goBackToDestinations: "returntodestinations",
     createVideoWall: "createvideowall",
};

export {
     Button,
     Input,
     InputPassword,
     InputNumbers,
     Textarea,
     Footer,
     NavBar,
     UserfulPageLayout,
     HelpInfo,
     UserfulReactTable,
     InitLoading,
     AuthenticationProvider as Authenticated,
     Paginated,
     EllipsisAvatars,
     useAuthenticationContext,
     GlobalModal,
     useGlobalModalContext,
     MODAL_COMPONENTS,
     GlobalLoading,
     useGlobalLoadingContext,
     LOADING_COMPONENTS,
     SideNav,
     Search,
     filterArrayBySearchValue,
     arrayHasValue,
     objectHasValue,
     MappingGroupLoading,
     DestinationGroupWidget,
     VideoWallWidget,
     I18nextConfig,
     DraggableAndResizable,
     DraggableAndResizablePopup,
     SortableMultiSelect,
     CustomToggle,
     CustomMenu,
     copyTextToClipboard,
     UserfulTooltip,
     StaticValueCopyField,
     DEST_VIDEO_WALL_URL_PARAMS,
     AppsConfig,
     cancelButton,
     discardChangesButton,
     CustomSearch,
     UserfulSearchBar,
     trimValue,
     CustomMenuClient,
     CustomToggleClient,
     saveWithWarningButton,
     UserfulReactTable2,
     ConfirmationToastContent,
     ConfirmToastContainerOptions,
     customClose,
     NoSourcePlaceholder,
     TextWidget,
     PasswordWidget,
     FormHeader,
     FieldHeader,
     CustomSwitch
};
