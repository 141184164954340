import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender,
    HeaderGroup,
    Header,
    filterFns,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    RowSelectionState,
    ColumnFiltersState,
    ColumnFilter,
} from "@tanstack/react-table";
import CustomSearch from "../CustomSearch";
import "./table.scss";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { faSortUp, faSortDown, faSort } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import PageSelector from "../../pagination/PageSelector";
import UserfulTooltip from "../../Widgets/UserfulTooltip";
import SearchV2 from "./SearchV2";
import { SelectionColumn } from "./TableSelect";
import "./table.scss";
import FilterFunction from "./FilterFunction";

interface IProps {
    columns: ColumnDef<any>[];
    data: Array<any>;
    id: string;
    pageSize?: number;
    search?: boolean;
    customSearch?: boolean;
    selectBox?: boolean;
    noData?: JSX.Element;
    minHeight?: string;
    noPagination?: boolean;
    getSearchValue?: Function;
    searchValue?: string;
    setSearchValue?: Function;
    hideSpanPages?: boolean;
    maxPageOptions?: number;
    onCellClick?: (e) => void;
    hiddenColumns?: string[];
    onSelectionChange?: (values: any[]) => void;
    /* rowSelection and setRowSelection props are used if we need to unset or set the selection of row from outside the table
    rowSelection is also defined as the prop in the table itself as well. That actually handles the change in table. 
    */
    rowSelection?: any;
    setRowSelection?: Function;
    enableColumnFilters?: boolean;
    customeColumnFilters?: ColumnFilter[];
    searchPlaceholder?: string;
    searchClass?: string;
}

/**
 * See examples in UclientTable2 or ZeroClientTable2
 * @param props
 * @returns
 */
export default function UserfulReactTable2(props: IProps) {
    const { t } = useTranslation();

    // for pagination
    const defaultItemsPerPage = 15;
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const hasSelection = props.selectBox === undefined || props.selectBox;
    const columns: ColumnDef<any>[] = hasSelection ? [SelectionColumn, ...props.columns] : props.columns;

    const table = useReactTable({
        columns,
        data: props.data,
        initialState: {
            pagination: {
                pageSize: props.pageSize || 15,
            },
            columnVisibility: props.hiddenColumns
                ? props.hiddenColumns.reduce((acc, curr) => ((acc[curr] = false), acc), {})
                : undefined,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        state: {
            globalFilter,
            rowSelection,
            columnFilters,
        },
        enableGlobalFilter: true,
        enableRowSelection: hasSelection,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: filterFns.includesString,
        enableColumnFilters: false,
        onColumnFiltersChange: setColumnFilters,
        autoResetPageIndex: false,
    });

    useEffect(() => {
        const totalPageCount = table.getPageCount();
        const pageIndex = table.getState().pagination.pageIndex;
        if(pageIndex < totalPageCount) return;
        table.setPageIndex(Number(table.getPageCount())-1);
    }, [globalFilter])


    useEffect(() => {
        if (props.customeColumnFilters && props.customeColumnFilters.length > 0) {
            const updatedFilterState = columnFilters.filter(
                (item) => props.customeColumnFilters.findIndex((f) => f.id === item.id) < 0
            );
            for (const filter of props.customeColumnFilters) {
                if (filter.value) {
                    updatedFilterState.push(filter);
                }
            }
            setColumnFilters(updatedFilterState);
        }
    }, [props.customeColumnFilters]);

    useEffect(() => {
        if (props.onSelectionChange) {
            props.onSelectionChange(table.getSelectedRowModel().flatRows.map((item) => item.original));
        }
        // this is implemented for Selected(x) functionality. It changes the rowSelection state outside the table when rowSelection is not {}
        // It prevents the continuous render resulting in error
        if (props.setRowSelection && Object.keys(rowSelection).length !== 0) {
            props.setRowSelection(rowSelection);
        }
    }, [rowSelection]);

    // this useEffect runs when rowSelection is being unset from outside the table and it only changes when we need to unselect all the rows.
    // this is for Selected(x) button functionality in uClient table
    useEffect(() => {
        if (props.rowSelection && Object.keys(props.rowSelection).length === 0) {
            setRowSelection(props.rowSelection);
        }
    }, [props.rowSelection]);

    if (props.noData && props.data.length < 1) {
        return props.noData;
    }

    const pageStart = Math.max(1, table.getState().pagination.pageSize * table.getState().pagination.pageIndex + 1);
    const pageEnd = Math.min(
        props.data.length,
        table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1)
    );

    const totalFiltered = table.getRowModel().rows.length;

    const pagination = () => (
        <div className="paginationFooter">
            <div className="sizeSelector">
                <Dropdown onSelect={(value) => table.setPageSize(Number(value))}>
                    <Dropdown.Toggle variant="light" id="pagination-size-dropdown" className="displayFontHeavy">
                        {table.getState().pagination.pageSize} {t("CommonUI.perPage")}{" "}
                        <FontAwesomeIcon icon={faAngleDown} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {defaultItemsPerPage < 5 && (
                            <Dropdown.Item eventKey={defaultItemsPerPage}>
                                {defaultItemsPerPage} {t("CommonUI.perPage")}
                            </Dropdown.Item>
                        )}
                        {props.pageSize && (
                            <Dropdown.Item eventKey={`${props.pageSize}`}>
                                {" "}
                                {props.pageSize} {t("CommonUI.perPage")}
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item eventKey={15}>15 {t("CommonUI.perPage")}</Dropdown.Item>
                        <Dropdown.Item eventKey={30}>30 {t("CommonUI.perPage")}</Dropdown.Item>
                        <Dropdown.Item eventKey={40}>40 {t("CommonUI.perPage")}</Dropdown.Item>
                        <Dropdown.Item eventKey={50}>50 {t("CommonUI.perPage")}</Dropdown.Item>
                        <Dropdown.Item eventKey={100}>100 {t("CommonUI.perPage")}</Dropdown.Item>
                        <Dropdown.Item eventKey={300}>300 {t("CommonUI.perPage")}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {!props.hideSpanPages && (
                    <span>
                        {pageStart} to {Math.min(pageEnd, totalFiltered)} of {totalFiltered}
                    </span>
                )}
            </div>
            <div className="pageSelector">
                <PageSelector
                    totalPages={table.getPageCount()}
                    currentPageIndex={table.getState().pagination.pageIndex}
                    onPageSelect={table.setPageIndex}
                    maxPageOptions={props.maxPageOptions || 5}
                />
            </div>
        </div>
    );

    return (
        <div>
            {(props.search === undefined || props.search) && (
                <div style={{ width: "402px" }} className="searchContainer">
                    {props.customSearch ? (
                        <CustomSearch
                            getSearchValue={props.getSearchValue}
                            searchValue={props.searchValue}
                            setSearchValue={props.setSearchValue}
                        />
                    ) : (
                            <SearchV2
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                placeholder={props.searchPlaceholder}
                                className={props.searchClass}
                        />
                    )}
                </div>
            )}
            <div
                id={`${props.id}-table-overall-div`}
                className="table-wrapper"
                style={{ minHeight: `calc(100vh - ${props.minHeight})` }}
            >
                <table id={`${props.id}-table`} style={{ width: "100%" }}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header: Header<any, any>, colIndex) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div style={{ display: "flex" }}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {header.column.getCanSort() && (
                                                        <UserfulTooltip title={t("CommonUI.toggleSort")}>
                                                            <div
                                                                className="sort-icon"
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {{
                                                                    asc: <FontAwesomeIcon icon={faSortUp} />,
                                                                    desc: <FontAwesomeIcon icon={faSortDown} />,
                                                                }[header.column.getIsSorted() as string] ?? (
                                                                        <FontAwesomeIcon icon={faSort} />
                                                                    )}
                                                            </div>
                                                        </UserfulTooltip>
                                                    )}
                                                    {header.column.getCanFilter() ? (
                                                        <div>
                                                            <FilterFunction column={header.column} table={table} />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id} onClick={props.onCellClick}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {!props.noPagination && pagination()}
            </div>
        </div>
    );
}
