"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STANDALONE_USERMANAGEMENTDATA = exports.STANDALONE_LOGIN_EVENTS = exports.STANDALONE_EXTERNAL_AUTH_DATA = exports.STANDALONE_ADMIN_EVENTS = void 0;
var STANDALONE_LOGIN_EVENTS = exports.STANDALONE_LOGIN_EVENTS = [{
  clientId: "userful-chronos-aether",
  time: 1692911439956,
  type: "LOGIN",
  ipAddress: "192.168.127.165",
  error: null,
  userName: "admin"
}, {
  clientId: "userful-chronos-aether",
  time: 1692911427305,
  type: "LOGIN",
  ipAddress: "192.168.127.165",
  error: null,
  userName: "admin"
}];
var STANDALONE_ADMIN_EVENTS = exports.STANDALONE_ADMIN_EVENTS = [{
  time: 1692395404783,
  resourceType: "GROUP",
  operationType: "CREATE",
  resourcePath: "groups/d300e21d-f444-4594-9085-0bf956ef5721",
  error: null,
  details: "{\"id\":\"d300e21d-f444-4594-9085-0bf956ef5721\",\"name\":\"aa\"}",
  eventAuth: {
    id: {
      value: "399f0025-dad4-4d19-ae31-74f323e58132"
    },
    realmId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    clientId: "b8fa3a55-cdb5-43aa-a5b7-e35b4af4608a",
    ipAddress: "127.0.0.1"
  },
  ipAddress: "127.0.0.1"
}];
var STANDALONE_EXTERNAL_AUTH_DATA = exports.STANDALONE_EXTERNAL_AUTH_DATA = {
  ldaps: [],
  ldapConfigData: {
    editModeOptions: {
      WRITABLE: "WRITABLE",
      UNSYNCED: "UNSYNCED",
      READ_ONLY: "READ_ONLY"
    },
    searchScopeOptions: {
      SUBTREE: "SUBTREE",
      ONE_LEVEL: "ONE_LEVEL"
    },
    bindTypeOptions: {
      SIMPLE: "simple",
      NONE: "none"
    },
    cachePolicieOptions: {
      EVICT_WEEKLY: "EVICT_WEEKLY",
      EVICT_DAILY: "EVICT_DAILY",
      NO_CACHE: "NO_CACHE",
      MAX_LIFESPAN: "MAX_LIFESPAN",
      DEFAULT: "DEFAULT"
    },
    membershipLdapTypeOptions: {
      UID: "UID",
      DN: "DN"
    },
    modeOptions: {
      IMPORT: "IMPORT",
      LDAP_ONLY: "LDAP_ONLY",
      READ_ONLY: "READ_ONLY"
    },
    userGroupsRetrieveStrategieOptions: {
      LOAD_GROUPS_BY_MEMBER_ATTRIBUTE: "LOAD_GROUPS_BY_MEMBER_ATTRIBUTE",
      GET_GROUPS_FROM_USER_MEMBEROF_ATTRIBUTE: "GET_GROUPS_FROM_USER_MEMBEROF_ATTRIBUTE"
    },
    vendorOptions: {
      ACTIVE_DIRECTORY: "Active Directory",
      RED_HAT_DIRECTORY_SERVER: "Red Hat Directory Server"
    }
  }
};
var STANDALONE_USERMANAGEMENTDATA = exports.STANDALONE_USERMANAGEMENTDATA = {
  users: [{
    id: {
      value: "399f0025-dad4-4d19-ae31-74f323e58132"
    },
    userProfile: {
      userName: "admin",
      firstName: "",
      lastName: "",
      email: null,
      enabled: true
    },
    requiredActions: [],
    attributes: null,
    groups: [{
      value: "b1a34a73-a8ec-4f26-b17e-cc12cc98e2f7"
    }],
    lastAccess: 1692911439000,
    federationLink: null,
    updated: new Date().getMilliseconds(),
    federatedEntities: []
  }],
  roles: [{
    id: {
      value: "48773446-63b8-420f-8cc1-6474fcefedeb"
    },
    name: "engage_user",
    displayName: "",
    description: "Grant limited access to Engage app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "46bd59c2-4f23-49cd-924f-46bb055224ce"
    },
    name: "artistic_admin",
    displayName: "",
    description: "Grant full access to Artistic app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "64845e41-984f-4fd2-ba97-39a7124c99a9"
    },
    name: "monitoring_dashboard",
    displayName: "",
    description: "Grant access to Monitoring Dashboard",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: true,
    attributes: {}
  }, {
    id: {
      value: "d4082c0d-7900-4721-b613-7fc07a67311a"
    },
    name: "artistic_user",
    displayName: "",
    description: "Grant limited access to Artistic app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "cc3c16c8-5080-4285-bcd9-aec98faf595f"
    },
    name: "admin",
    displayName: "",
    description: "Grant full access to apps, configurations and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "c9a8c57c-0ad7-4686-be58-35e801bf2ffa"
    },
    name: "decisions_operator",
    displayName: "",
    description: "Grant limited access to Decisions screen and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "b1feadd7-76c8-4ad9-8e74-de57ed85ec9b"
    },
    name: "trends_admin",
    displayName: "",
    description: "Grant full access to Trends app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "d73433f9-c304-4aec-b8b5-7c4d11841854"
    },
    name: "remote_user",
    displayName: "",
    description: "Grant limited access to Remote app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "b93ac023-7e35-4c73-8e23-0504fa12ce01"
    },
    name: "system_config_admin",
    displayName: "",
    description: "Grant limited access to System Configuration",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: true,
    attributes: {}
  }, {
    id: {
      value: "d548e984-4f4b-43bd-9391-d1e68d915c00"
    },
    name: "engage_admin",
    displayName: "",
    description: "Grant full access to Engage app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "2b9543b2-0b2d-421c-96d8-573b57b78a8e"
    },
    name: "system_config_viewer",
    displayName: "",
    description: "Grant view access to System Configuration",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: true,
    attributes: {}
  }, {
    id: {
      value: "db173d3c-d8f2-436a-9edd-b24f2585c7e5"
    },
    name: "tablet_ctrl_user",
    displayName: "uControl User",
    description: "Grant limited access to the uControl App. Can only start, and stop Presets",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "5c5dae88-cbaf-4c25-870d-5cf6e7c887f4"
    },
    name: "trends_user",
    displayName: "",
    description: "Grant limited access to Trends app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "74345842-2706-4fff-8237-33a3f9192830"
    },
    name: "decisions_admin",
    displayName: "",
    description: "Grant full access to Decisions screen and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "ea650d3c-2be5-4872-a6a7-8ac4e7a6f66a"
    },
    name: "tablet_ctrl_admin",
    displayName: "uControl Admin",
    description: "Grant full access to the uControl App. Can create, edit, delete, start, and stop Presets",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "75a615ca-90d3-4a02-b473-416c34ccff37"
    },
    name: "user_management_admin",
    displayName: "",
    description: "Grant full access to User Management Interface and Initial Seup App",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: true,
    attributes: {}
  }, {
    id: {
      value: "a6d63f03-ab2f-4f99-83ca-a6495576f4e8"
    },
    name: "remote_admin",
    displayName: "",
    description: "Grant full access to Remote app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "8172912c-a07d-4328-8145-3a7a027e6c58"
    },
    name: "spaces_admin",
    displayName: "",
    description: "Grant full access to Spaces app and resources",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: false,
    attributes: {}
  }, {
    id: {
      value: "f8945b10-a079-4bb7-96f1-e1229acbf082"
    },
    name: "sources_destinations_admin",
    displayName: "",
    description: "Grant access to sources and destinations",
    composite: true,
    clientRole: false,
    containerId: "b5b0ff17-6465-405e-bdee-64b55dbb3da8",
    editable: true,
    attributes: {}
  }],
  groups: [{
    id: {
      value: "b1a34a73-a8ec-4f26-b17e-cc12cc98e2f7"
    },
    name: "Administrators",
    displayName: "Administrators",
    description: "Grant Access to user management, apps and all resources.",
    path: "/Administrators",
    editable: false,
    members: [{
      value: "399f0025-dad4-4d19-ae31-74f323e58132"
    }],
    roles: [{
      value: "64845e41-984f-4fd2-ba97-39a7124c99a9"
    }, {
      value: "e162ea80-584d-41de-b938-251b404764bd"
    }, {
      value: "5ed4bc5a-2dd4-4928-8d34-90d1f438cbc0"
    }, {
      value: "cc3c16c8-5080-4285-bcd9-aec98faf595f"
    }, {
      value: "3807da3f-24d1-4fae-9906-62e5afc5efd9"
    }, {
      value: "b93ac023-7e35-4c73-8e23-0504fa12ce01"
    }, {
      value: "2b9543b2-0b2d-421c-96d8-573b57b78a8e"
    }, {
      value: "7a5552c8-473d-437d-a82a-88c6a0fb0cf5"
    }, {
      value: "fd77811b-093c-4ed9-ba46-517fb6e36c32"
    }, {
      value: "ff777fe5-5ff2-4046-80a4-fc8bd38865d7"
    }, {
      value: "c5db5d71-965c-475b-beac-29ab002d5d91"
    }, {
      value: "ea650d3c-2be5-4872-a6a7-8ac4e7a6f66a"
    }, {
      value: "75a615ca-90d3-4a02-b473-416c34ccff37"
    }, {
      value: "f8945b10-a079-4bb7-96f1-e1229acbf082"
    }],
    attributes: {}
  }, {
    id: {
      value: "77409d1c-6402-4d03-a937-29733f9ff4ac"
    },
    name: "ArtisticAdministrators",
    displayName: "Artistic Admin",
    description: "Grant Access to Artistic App. Grant full access to all Artistic resources.",
    path: "/ArtisticAdministrators",
    editable: false,
    members: [],
    roles: [{
      value: "46bd59c2-4f23-49cd-924f-46bb055224ce"
    }, {
      value: "ff777fe5-5ff2-4046-80a4-fc8bd38865d7"
    }],
    attributes: {}
  }, {
    id: {
      value: "60099841-87ab-4aeb-a197-e65075fb227f"
    },
    name: "ArtisticUsers",
    displayName: "Artistic Users",
    description: "Grant access to Artistic App. Plus ability to create mapping groups and remap sources/destinations to already created mapping groups, and the ability to create new sources, but no permissions to edit or delete. Also have access to play lists and schedules.",
    path: "/ArtisticUsers",
    editable: false,
    members: [],
    roles: [{
      value: "d4082c0d-7900-4721-b613-7fc07a67311a"
    }, {
      value: "ff777fe5-5ff2-4046-80a4-fc8bd38865d7"
    }],
    attributes: {}
  }, {
    id: {
      value: "2fb490ea-b856-4f37-b8f9-f9cee3d259ed"
    },
    name: "DecisionsAdministrators",
    displayName: "Decisions Admin",
    description: "Grant Access to Decisions App. Grant full access to all Decisions resources.",
    path: "/DecisionsAdministrators",
    editable: false,
    members: [],
    roles: [{
      value: "5ed4bc5a-2dd4-4928-8d34-90d1f438cbc0"
    }, {
      value: "74345842-2706-4fff-8237-33a3f9192830"
    }],
    attributes: {}
  }, {
    id: {
      value: "0ad6b614-a2aa-4759-a524-a4be83c77d75"
    },
    name: "DecisionsOperators",
    displayName: "Decisions Users",
    description: "Grant access to Decisions App. Plus ability to activate/deactivate insets and move them arround. Can also create and load Layouts, but no permissions to delete.",
    path: "/DecisionsOperators",
    editable: false,
    members: [],
    roles: [{
      value: "5ed4bc5a-2dd4-4928-8d34-90d1f438cbc0"
    }, {
      value: "c9a8c57c-0ad7-4686-be58-35e801bf2ffa"
    }],
    attributes: {}
  }, {
    id: {
      value: "c0b3ebae-bbb7-4a26-aef0-aae5c5bf3f35"
    },
    name: "EngageAdministrators",
    displayName: "Engage Admin",
    description: "Grant Access to Engage App. Grant full access to all Engage resources.",
    path: "/EngageAdministrators",
    editable: false,
    members: [],
    roles: [{
      value: "d548e984-4f4b-43bd-9391-d1e68d915c00"
    }, {
      value: "fd77811b-093c-4ed9-ba46-517fb6e36c32"
    }],
    attributes: {}
  }, {
    id: {
      value: "fa8fcaca-31a6-4fc2-894e-1f0120f12668"
    },
    name: "EngageUsers",
    displayName: "Engage Users",
    description: "Grant access to Engage App. Plus ability to create mapping groups and remap sources/destinations to already created mapping groups, and the ability to create new sources, but no permissions to edit or delete. Also have access to content management.",
    path: "/EngageUsers",
    editable: false,
    members: [],
    roles: [{
      value: "48773446-63b8-420f-8cc1-6474fcefedeb"
    }, {
      value: "fd77811b-093c-4ed9-ba46-517fb6e36c32"
    }],
    attributes: {}
  }, {
    id: {
      value: "d11dcc46-e765-4537-ba31-1977d6a7c9de"
    },
    name: "RemoteAdministrators",
    displayName: "Remote Admin",
    description: "Grant Access to Remote App. Grant full access to all Remote resources.",
    path: "/RemoteAdministrators",
    editable: false,
    members: [],
    roles: [{
      value: "3807da3f-24d1-4fae-9906-62e5afc5efd9"
    }, {
      value: "a6d63f03-ab2f-4f99-83ca-a6495576f4e8"
    }],
    attributes: {}
  }, {
    id: {
      value: "6a0c54dd-1e49-4b3e-88f4-d209f3cd2b8f"
    },
    name: "RemoteUsers",
    displayName: "Remote Users",
    description: "Grant access to Remote App. Only channels access.",
    path: "/RemoteUsers",
    editable: false,
    members: [],
    roles: [{
      value: "3807da3f-24d1-4fae-9906-62e5afc5efd9"
    }, {
      value: "d73433f9-c304-4aec-b8b5-7c4d11841854"
    }],
    attributes: {}
  }, {
    id: {
      value: "050fd978-2eee-480a-aa38-760bdf342180"
    },
    name: "SpacesAdministrators",
    displayName: "Spaces Admin",
    description: "Grant Access to Spaces App. Grant full access to all Spaces resources.",
    path: "/SpacesAdministrators",
    editable: false,
    members: [],
    roles: [{
      value: "e162ea80-584d-41de-b938-251b404764bd"
    }, {
      value: "8172912c-a07d-4328-8145-3a7a027e6c58"
    }],
    attributes: {}
  }, {
    id: {
      value: "ceff3089-c114-496e-b593-4b30b56b2ef0"
    },
    name: "TrendsAdministrators",
    displayName: "Trends Admin",
    description: "Grant Access to Trends App. Grant full access to all Trends resources.",
    path: "/TrendsAdministrators",
    editable: false,
    members: [],
    roles: [{
      value: "b1feadd7-76c8-4ad9-8e74-de57ed85ec9b"
    }, {
      value: "7a5552c8-473d-437d-a82a-88c6a0fb0cf5"
    }],
    attributes: {}
  }, {
    id: {
      value: "58b0d8f1-fd56-40f6-b103-860dea5abf51"
    },
    name: "TrendsUsers",
    displayName: "Trends Users",
    description: "Grant access to Trends App. Plus ability to create mapping groups and remap sources/destinations to already created mapping groups, and the ability to create new sources, but no permissions to edit or delete. Also have access to play lists, multi window and schedules.",
    path: "/TrendsUsers",
    editable: false,
    members: [],
    roles: [{
      value: "7a5552c8-473d-437d-a82a-88c6a0fb0cf5"
    }, {
      value: "5c5dae88-cbaf-4c25-870d-5cf6e7c887f4"
    }],
    attributes: {}
  }, {
    id: {
      value: "d300e21d-f444-4594-9085-0bf956ef5721"
    },
    name: "aa",
    displayName: "",
    description: "",
    path: "/aa",
    editable: true,
    members: [],
    roles: [],
    attributes: {}
  }]
};