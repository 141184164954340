import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import usermgtSlice from './usermgtSlice';
import ldapSlice from './ldapSlice';
import levelSlice from './levelSlice';
import samlSlice from './samlSlice';

export const UsermgtContext = React.createContext(null);

const usermgtStore = configureStore({
    reducer: {
        usermgtStore: usermgtSlice,
        ldapStore: ldapSlice,
        levelStore: levelSlice,
        samlStore: samlSlice
    },
})
export default usermgtStore;

export type State = ReturnType<typeof usermgtStore.getState>;
export type Dispatch = typeof usermgtStore.dispatch;
export const useUsermgtSelector: TypedUseSelectorHook<State> = createSelectorHook(UsermgtContext);
export const useUsermgtDispatch = createDispatchHook<Dispatch>(UsermgtContext)