import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
     faHouse,
     faCircleQuestion,
     faMagnifyingGlass,
     faBolt,
     faLightbulb,
     faExpand,
     faFlag,
     faBell,
     faCircleNotch,
     faComment,
     faBars,
} from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeftToLine } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import NavBarTitle from "./NavBarTitle";
import screenfull from "screenfull";
import { useAuthenticationContext } from "userful-chronos-common-store/dist/auth";
import UserfulTooltip from "../Widgets/UserfulTooltip";
import { getRootPath } from "userful-chronos-app-common-js/dist/routing";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import Cookies from 'js-cookie';
import "./NavBar.scss";
import Button from "../Button/Button";
import DropdownItem from "../Widgets/Dropdown/DropdownItem";

const COOKIE_KEY_PREFIX = "userful-notification-";

const resetHideNotifications = () => {
     const cookies = Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')));
     for (const cookieKey of Object.keys(cookies)) {
          if (cookieKey && cookieKey.startsWith(COOKIE_KEY_PREFIX)) {
               Cookies.remove(cookieKey);
          }
     }
}

interface IProps {
     navigation?: Function,
     showSearch?: boolean,
     showHome?: boolean,
     showLightBulb?: boolean,
     showHelp?: boolean,
     showBolt?: boolean,
     showWindowMax?: boolean,
     showFlag?: boolean,
     showBell?: boolean,
     hideHelp?: boolean,
     appTitleBar?: string,
     helpURL?: string,
     logoImgURL?: string,
}

function NavBar(props: IProps) {
     const subKey = "serviceInfo"

     const {
          logout,
          state: { keycloak, }
     } = useAuthenticationContext();
     const serviceInfo = useAuthenticationContext().state[subKey];

     const [showHelp, setShowHelp] = useState(props.showHelp || true);
     const [showWindowMax, setShowWindowMax] = useState(props.showWindowMax || true);
     const [username, setUsername] = useState<string>(getGlobalStates().userData && getGlobalStates().userData.userName);
     const [hideHelp, setHideHelp] = useState(!props.hideHelp);

     const getApp = () => {
          if (props.appTitleBar) {
               return props.appTitleBar;
          }
          if (getGlobalStates().overrideAppID) {
               return getGlobalStates().overrideAppID;
          }
          if (getGlobalStates().appID) {
               return getGlobalStates().appID.value;
          }
          return null;
     };

     const [currentApp, setCurrentApp] = useState(getApp());

     useEffect(() => {
          if (serviceInfo) {
               setCurrentApp(getApp());
          }
     }, [serviceInfo]);

     useEffect(() => {
          if (props.appTitleBar) {
               setCurrentApp(props.appTitleBar);
          }
     }, [props.appTitleBar]);

     useEffect(() => {
          if (keycloak && keycloak.authenticated) {
               setUsername((getGlobalStates().userData?.userName)
                    || keycloak.tokenParsed?.preferred_username);
          }
     }, [keycloak]);


     const toggleFullScreen = () => {
          screenfull.toggle();
     };

     const logoutAndRefreshPage = () => {
          logout();
     };

     const { t } = useTranslation();
     const clientId = getGlobalStates().keycloak?.tokenParsed?.preferred_username;

     return <header className="userful-main-header">
          <div className="userful-main-header-container">
               {props.navigation &&
                    <UserfulTooltip placement="bottom" title="Toggle Navigation">
                         <Button variant="secondary" iconOnly onClick={() => { props.navigation() }}>
                              <FontAwesomeIcon icon={faBars} />
                         </Button>
                    </UserfulTooltip>}
               <a href={getRootPath()}>
                    <img draggable="false" className="userful-main-header-logo"
                         src={props.logoImgURL || "./images/userful-secondary-black.svg"} alt="Userful">
                    </img>
               </a>
               <NavBarTitle currentApp={currentApp} />
          </div>
          <div className="userful-main-header-container">
               {hideHelp && showHelp && (
                    <UserfulTooltip
                         placement='bottom'
                         title={t('Navbar.tooltip.help')}
                    >
                         <Button variant={"secondary"} iconOnly onClick={() => { window.open(props.helpURL, '_blank').focus(); }}> <FontAwesomeIcon icon={faCircleQuestion} />
                         </Button>
                    </UserfulTooltip>
               )}
               {showWindowMax && (
                    <UserfulTooltip
                         placement='bottom'
                         title={t('Navbar.tooltip.fullscreen')}
                    >
                         <Button variant={"secondary"} iconOnly onClick={() => toggleFullScreen()}><FontAwesomeIcon icon={faExpand} />
                         </Button>
                    </UserfulTooltip>
               )}
               {!!username && <Dropdown drop="down" align="end">
                    <Dropdown.Toggle bsPrefix="userful-main-header-account" id="dropdownToggle" as={Button}>
                         <p>{clientId && clientId[0].toUpperCase()}</p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="userful-main-header-account-menu">
                         <div className="userful-main-header-account-header">
                              <div className="userful-main-header-account-icon"><p>{clientId && clientId[0].toUpperCase()}</p></div>
                              <div className="userful-main-header-account-text">{username}</div>
                         </div>
                         <hr />
                         <Dropdown.Item onClick={() => resetHideNotifications()}>
                              <DropdownItem text={t('CommonUI.Header.resetNotification')} icon={faComment} />
                         </Dropdown.Item>
                         <Dropdown.Item onClick={() => logoutAndRefreshPage()}>
                              <DropdownItem text={t('CommonUI.Header.userLogout')} icon={faArrowLeftToLine} />
                         </Dropdown.Item>
                    </Dropdown.Menu>
               </Dropdown>}
          </div>
     </header>
}

export default NavBar;
